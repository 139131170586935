/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { call, put, takeLatest, all } from '@redux-saga/core/effects'

import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILED } from '../../actions/oer'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'

const apiUrl = '/oe-rules'

function* getOERSaga() {
  try {
    const response = yield call(apiGetter, apiUrl)
    yield put({
      type: FETCH_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield all([
      put({ type: FETCH_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Opportunity Engine rules', error })),
    ])
  }
}

function* watchSagas() {
  yield takeLatest(FETCH_REQUEST, getOERSaga)
}

export const oerSagas = [watchSagas]

import React, { createContext } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import theme from '../theme'
import { featureFlagService } from '../services/featureFlagService'

const preferDarkMode =
  featureFlagService.themeSwitcher && !!window.matchMedia?.('(prefers-color-scheme: dark)').matches

const ThemeContext = createContext<Theme>({
  darkMode: preferDarkMode,
  toggleDarkMode: () => {},
})

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useLocalStorage<boolean>('darkMode', preferDarkMode)
  const toggleDarkMode = () => setDarkMode(state => !state)

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <MuiThemeProvider theme={theme({ darkMode })}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

type Theme = {
  darkMode: boolean
  toggleDarkMode: () => void
}

export default ThemeContext

export interface QSStrategy {
  build(request: string): string
}

class QSBuilder implements QSStrategy {
  strategies: QSStrategy[]

  constructor() {
    this.strategies = []
  }

  add(strategy: QSStrategy): QSBuilder {
    this.strategies.push(strategy)
    return this
  }

  build(request): string {
    const queryStrings = this.strategies.map(strategy => strategy.build(request))

    return queryStrings
      .filter(query => query)
      .reduce((queryString, query, index) => {
        const seperator = index ? '&' : '?'
        return `${queryString}${seperator}${query}`
      }, '')
  }
}

export default QSBuilder

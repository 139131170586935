/**
 * Contains notification action handlers and reducer
 * @module ducks
 * @author Mark Colquhoun <mark.colquhoun@origin.com.au>
 */

import { NotificationStateType } from '../reducers/notification'
/**
 * Actions
 */

export const ENQUEUE_MESSAGE = 'message/ENQUEUE_MESSAGE'
export const REMOVE_MESSAGE = 'message/REMOVE_MESSAGE'

/**
 * Types
 */

export interface InfoNotificationActionType {
  type: typeof ENQUEUE_MESSAGE
  payload: NotificationStateType
}

export interface SuccessNotificationActionType {
  type: typeof ENQUEUE_MESSAGE
  payload: NotificationStateType
}

export interface WarningNotificationActionType {
  type: typeof ENQUEUE_MESSAGE
  payload: NotificationStateType
}

export interface ErrorNotificationActionType {
  type: typeof ENQUEUE_MESSAGE
  payload: NotificationStateType
}

export interface RemoveMessageType {
  type: typeof REMOVE_MESSAGE
  payload: string
}

export type NotifierActionTypes =
  | InfoNotificationActionType
  | SuccessNotificationActionType
  | WarningNotificationActionType
  | ErrorNotificationActionType
  | RemoveMessageType

/**
 * Action Creators
 */

export const enqueueInfoMessage = (notification): InfoNotificationActionType => {
  return {
    type: ENQUEUE_MESSAGE,
    payload: {
      key: new Date().getTime() + Math.random(),
      ...notification,
      options: {
        ...notification.options,
        variant: 'info',
      },
    },
  }
}

export const enqueueSuccessMessage = (notification): SuccessNotificationActionType => {
  return {
    type: ENQUEUE_MESSAGE,
    payload: {
      key: new Date().getTime() + Math.random(),
      ...notification,
      options: {
        ...notification.options,
        variant: 'success',
      },
    },
  }
}

export const enqueueWarningMessage = (notification): WarningNotificationActionType => {
  return {
    type: ENQUEUE_MESSAGE,
    payload: {
      key: new Date().getTime() + Math.random(),
      ...notification,
      options: {
        ...notification.options,
        variant: 'warning',
      },
    },
  }
}

const getErrorMessage = (error): string => {
  let { message } = error

  if (error.response && error.response.data && error.response.data.error) {
    message = error.response.data.error
  }

  return message
}

export const enqueueErrorMessage = (notification): ErrorNotificationActionType => {
  return {
    type: ENQUEUE_MESSAGE,
    payload: {
      key: new Date().getTime() + Math.random(),
      ...notification,
      message: `${notification.message && `${notification.message}: `} ${getErrorMessage(
        notification.error
      )}`,
      options: {
        ...notification.options,
        variant: 'error',
        persist: true,
      },
    },
  }
}

export const removeMessage = (key: string): RemoveMessageType => {
  return {
    type: REMOVE_MESSAGE,
    payload: key,
  }
}

import { call, put, takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'

import { GO_TO_REDIRECT_URL } from '../../actions/actionTypes'

import { getStoredValue, removeStoredValue, isValidRedirectUrl, StorageKeys } from '../../utils'

function* goToRedirectUrl() {
  const redirectUrl: string = yield call(getStoredValue, StorageKeys.REDIRECT_URL)
  if (redirectUrl && redirectUrl.length > 0) {
    if (isValidRedirectUrl(redirectUrl)) {
      yield put(push(redirectUrl))
    }
    yield call(removeStoredValue, StorageKeys.REDIRECT_URL)
  }
}

function* watchRedirectUrlSet() {
  yield takeLatest(GO_TO_REDIRECT_URL, goToRedirectUrl)
}

export const authSagas = [watchRedirectUrlSet]

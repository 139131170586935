import * as ActionTypes from '../../actions/actionTypes'
import { dayjs, exists } from '../../utils'
import { VIEWS } from '../../constants'

export interface ConfigState {
  pageTitle: string
  myActions: { from; to; period? }
  wellreviews: { from; to; period? }
  submissions: { from; to; period? }
  settings: object
}

const DEFAULT_PERIOD = 1

const from = dayjs().subtract(DEFAULT_PERIOD, 'days').startOf('day')
const to = dayjs().subtract(1, 'days').endOf('day')

const settingDefaults = {
  [VIEWS.DASHBOARD]: {
    useMyRoles: false,
  },
  [VIEWS.TASKS]: {
    applyGlobalFilters: false,
  },
  [VIEWS.WELLLISTS]: {
    applyGlobalFilters: false,
  },
}

const initialState: ConfigState = {
  pageTitle: '',
  myActions: { from, to, period: DEFAULT_PERIOD },
  wellreviews: {
    from: dayjs().subtract(DEFAULT_PERIOD, 'days').startOf('day'),
    to: dayjs().add(DEFAULT_PERIOD, 'days').endOf('day'),
    period: DEFAULT_PERIOD,
  },
  submissions: { from, to, period: DEFAULT_PERIOD },
  settings: Object.keys(VIEWS).reduce((settings, key) => {
    const defaults = settingDefaults[VIEWS[key]]
    return {
      ...settings,
      [VIEWS[key]]: {
        useMyRoles: exists(defaults?.useMyRoles) ? defaults?.useMyRoles : true,
        applyGlobalFilters: exists(defaults?.applyGlobalFilters)
          ? defaults?.applyGlobalFilters
          : undefined,
      },
    }
  }, {}),
}

const configReducer = (state: ConfigState = initialState, action): ConfigState => {
  switch (action.type) {
    case ActionTypes.SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.payload,
      }
    }

    case ActionTypes.SET_REPORTING_PERIOD: {
      return {
        ...state,
        [action.payload.periodName]: action.payload.period,
      }
    }

    case ActionTypes.SET_VIEW_SETTINGS: {
      const { view, settings } = action.payload
      return {
        ...state,
        settings: {
          ...state.settings,
          [view]: { ...state.settings[view], ...settings },
        },
      }
    }

    default:
      return state
  }
}

export default configReducer

const rolesList = [
  {
    value: 'WPA_WELL_PERFORMANCE_TECHNICIAN',
    label: 'WELL PERFORMANCE TECHNICIAN',
  },
  { value: 'WPA_WELL_INTERVENTION_LEAD', label: 'WELL INTERVENTION LEAD' },
  { value: 'WPA_PRODUCTION_ENGINEER', label: 'PRODUCTION ENGINEER' },
  { value: 'WPA_PANEL_OPERATOR', label: 'PANEL OPERATOR' },
  { value: 'WPA_FIELD_OPERATOR', label: 'FIELD OPERATOR' },
  { value: 'WPA_DATA_SCIENTIST', label: 'DATA SCIENTIST' },
  { value: 'WPA_TRIAL_USERS', label: 'TRIAL USER' },
  { value: 'WPA_TRIAL_USER_FIELD', label: 'TRIAL USER FIELD' },
  { value: 'WPA_STP', label: 'STP' },
  { value: 'WPA_RESERVOIR_ENGINEERS', label: 'RESERVOIR ENGINEERS' },
  { value: 'WPA_PROCESS_ENGINEER', label: 'PROCESS ENGINEER' },
]

export default rolesList

import { all, call, put, takeLatest } from '@redux-saga/core/effects'
import * as suspensions from '../../actions/suspensions'
import { getSuspensions, createSuspension, updateSuspension } from '../../api/suspensions'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'
import { queryStringBuilder } from '../../api'

function* fetchSuspensions(action: suspensions.GetSuspensionsActionType) {
  const { payload: request } = action

  try {
    const apiUrl = `/suspensions${queryStringBuilder.build(request)}`
    const response = yield call(apiGetter, apiUrl, { ...request })
    // const response = yield call(getSuspensions, request)
    yield put({
      type: suspensions.FETCH_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield all([
      put({ type: suspensions.FETCH_FAILED, payload: error }),
      put(
        enqueueErrorMessage({
          message: 'Failed to load suspensions',
          error,
        })
      ),
    ])
  }
}

function* postSuspension(action: suspensions.CreateSuspensionsActionType) {
  const { payload: request } = action

  try {
    const response = yield call(createSuspension, request)
    yield put({
      type: suspensions.CREATE_SUCCESS,
      payload: response.data.body,
    })
  } catch (error) {
    yield all([
      put({ type: suspensions.CREATE_FAILED, payload: error }),
      put(
        enqueueErrorMessage({
          message: 'Failed to create suspension',
          error,
        })
      ),
    ])
  }
}

function* patchSuspension(action: suspensions.UpdateSuspensionsActionType) {
  const { payload: request } = action

  try {
    const response = yield call(updateSuspension, request)
    yield put({
      type: suspensions.UPDATE_SUCCESS,
      payload: response.data.body,
    })
  } catch (error) {
    yield all([
      put({ type: suspensions.UPDATE_FAILED, payload: error }),
      put(
        enqueueErrorMessage({
          message: 'Failed to update suspension',
          error,
        })
      ),
    ])
  }
}

function* watchSagas() {
  yield takeLatest(suspensions.FETCH_REQUEST, fetchSuspensions)
  yield takeLatest(suspensions.CREATE_REQUEST, postSuspension)
  yield takeLatest(suspensions.UPDATE_REQUEST, patchSuspension)
}

export default [watchSagas]

// AUTH
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const GO_TO_REDIRECT_URL = 'GO_TO_REDIRECT_URL'
export const STORE_AUTH_DATA = 'STORE_AUTH_DATA'
export const STORE_AUTH_DATA_FAILURE = 'STORE_AUTH_DATA_FAILURE'

// Config
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SET_REPORTING_PERIOD = 'SET_REPORTING_PERIOD'
export const SET_VIEW_SETTINGS = 'SET_VIEW_CONFIG'

// Tasks
export const GET_TASKS = 'GET_TASKS'
export const GET_OPENSEARCH_TASKS = 'GET_OPENSEARCH_TASKS'
export const GET_OPENSEARCH_TASKS_BY_WELL = 'GET_OPENSEARCH_TASKS_BY_WELL'
export const CLEAR_OPENSEARCH_TASKS = 'CLEAR_OPENSEARCH_TASKS'
export const GET_DASHBOARD_OPENSEARCH_TASKS = 'GET_DASHBOARD_OPENSEARCH_TASKS'
export const GET_WELLS_BY_OPENTASKS = 'GET_WELLS_BY_OPENTASKS'
export const GET_WELLS_BY_OPENTASKS_SUCCESS = 'GET_WELLS_BY_OPENTASKS_SUCCESS'
export const GET_WELLS_BY_OPENTASKS_ERROR = 'GET_WELLS_BY_OPENTASKS_ERROR'
export const GET_TASKS_SILENT = 'GET_TASKS_SILENT'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR'
export const GET_TASKS_FOR_WELL = 'GET_TASKS_FOR_WELL' // todo: review this. it is probably not used anywhere
export const GET_TASKS_FOR_WELL_SUCCESS = 'GET_TASKS_FOR_WELL_SUCCESS' // todo: review this. it is probably not used anywhere
export const GET_TASK = 'GET_TASK'
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS'
export const GET_TASK_ERROR = 'GET_TASK_ERROR'
export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const GET_DASHBOARD_TASKS_SUCCESS = 'GET_DASHBOARD_TASKS_SUCCESS'
export const GET_OPENSEARCH_TASKS_BY_WELL_SUCCESS = 'GET_OPENSEARCH_TASKS_BY_WELL_SUCCESS'
export const CLEAR_DASHBOARD_TASKS = 'CLEAR_DASHBOARD_TASKS'
export const GET_DASHBOARD_TASKS_ERROR = 'GET_DASHBOARD_TASKS_ERROR'
export const GET_OPENSEARCH_TASKS_BY_WELL_ERROR = 'GET_OPENSEARCH_TASKS_BY_WELL_ERROR'

// Notes
export const POST_NOTES = 'POST_NOTES'
export const POST_NOTES_SUCCESS = 'POST_NOTES_SUCCESS'
export const POST_NOTES_ERROR = 'POST_NOTES_ERROR'
export const GET_NOTES_FOR_TASK = 'GET_NOTES_FOR_TASK'
export const GET_NOTES_FOR_TASK_SUCCESS = 'GET_NOTES_FOR_TASK_SUCCESS'
export const GET_NOTES_FOR_TASK_ERROR = 'GET_NOTES_FOR_TASK_ERROR'

// Filters
export const GET_FILTERS = 'GET_FILTERS'
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS'
export const GET_FILTERS_ERROR = 'GET_FILTERS_ERROR'
export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR'
export const POST_FILTERS = 'POST_FILTERS'
export const POST_FILTERS_SUCCESS = 'POST_FILTERS_SUCCESS'
export const POST_FILTERS_ERROR = 'POST_FILTERS_ERROR'

// Watchlists
export const GET_WATCH_LISTS = 'GET_WATCH_LISTS'
export const GET_WATCH_LISTS_SUCCESS = 'GET_WATCH_LISTS_SUCCESS'
export const UPDATE_WATCH_LIST = 'UPDATE_WATCH_LIST'
export const UPDATE_WATCH_LIST_SUCCESS = 'UPDATE_WATCH_LIST_SUCCESS'
export const CREATE_WATCH_LIST = 'CREATE_WATCH_LIST'
export const CREATE_WATCH_LIST_SUCCESS = 'CREATE_WATCH_LIST_SUCCESS'
export const DELETE_WATCH_LIST = 'DELETE_WATCH_LIST'
export const DELETE_WATCH_LIST_SUCCESS = 'DELETE_WATCH_LIST_SUCCESS'

// Well
export const SET_WELL = 'SET_WELL'

// Well lists
export const GET_WELLS = 'GET_WELLS'
export const GET_WELLS_SUCCESS = 'GET_WELLS_SUCCESS'
export const GET_WELLS_AGGREGATION = 'GET_WELLS_AGGREGATION'
export const GET_WELLS_AGGREGATION_SUCCESS = 'GET_WELLS_AGGREGATION_SUCCESS'

// Suspensions
export const GET_SUSPENSIONS = 'GET_SUSPENSIONS'

// Well tabs
export const GET_WELL_TABS = 'GET_WELL_TABS'
export const GET_WELL_TABS_SUCCESS = 'GET_WELL_TABS_SUCCESS'
export const UPSERT_WELL_TAB = 'UPSERT_WELL_TAB'
export const UPSERT_WELL_TAB_SUCCESS = 'UPSERT_WELL_TAB_SUCCESS'
export const DELETE_WELL_TAB = 'DELETE_WELL_TAB'
export const DELETE_WELL_TAB_SUCCESS = 'DELETE_WELL_TAB_SUCCESS'

import * as ActionTypes from '../../actions/actionTypes'
import { AuthActionTypes, AuthData, AuthState } from '../../actions/authTypes'

export const initialState: AuthState = {
  data: {
    roles: [],
    userName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    authorised: false,
  },
  error: null,
}

const authReducer = (state: AuthState = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case ActionTypes.STORE_AUTH_DATA:
      const { roles, userName, lastName, email, jobTitle, authorised }: AuthData = action.payload
      return {
        data: {
          ...state.data,
          roles,
          userName,
          lastName,
          email,
          jobTitle,
          authorised,
        },
        error: null,
      }
    case ActionTypes.STORE_AUTH_DATA_FAILURE:
      return {
        data: {
          ...state.data,
          authorised: false,
        },
        error: action.payload,
      }
    default:
      return { ...state, error: null }
  }
}

export default authReducer

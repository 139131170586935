import { GET_WELLS, GET_WELLS_SUCCESS } from '../../actions/actionTypes'
import { FetchWellsContext } from '../../actions/wells'

export interface WellListsState {
  wells: object
  totalCount: number
  isWellListsLookUpPending: boolean
  context: FetchWellsContext
}

const initialState: WellListsState = {
  wells: {},
  totalCount: 0,
  isWellListsLookUpPending: false,
  context: FetchWellsContext.None,
}

const wellListsReducer = (
  state: WellListsState = initialState,
  { type, payload, context }
): WellListsState => {
  switch (type) {
    case GET_WELLS: {
      const newState =
        payload.reset === true
          ? { ...state, wells: [], totalCount: 0, isWellListsLookUpPending: true }
          : { ...state, isWellListsLookUpPending: true }
      return newState
    }
    case GET_WELLS_SUCCESS: {
      const wells = payload.body.data || []
      const totalCount = payload.body.totalCount || 0

      return {
        wells,
        isWellListsLookUpPending: false,
        totalCount,
        context,
      }
    }

    default:
      return state
  }
}

export default wellListsReducer

import { SubmissionHealthType, SubmissionOutcomesType } from '../types'

/**
 * Actions
 */
export const FETCH_REQUEST = 'submissions/FETCH_SUBMISSIONS_REQUEST'
export const FETCH_SUCCESS = 'submissions/FETCH_SUBMISSIONS_SUCCESS'
export const FETCH_FAILED = 'submissions/FETCH_SUBMISSIONS_FAILED'

/**
 * Types
 */
export interface GetSubmissionsAction {
  type: typeof FETCH_REQUEST
  payload: { from?: string; to?: string; key?: string }
}

interface GetSubmissionsActionSucessType {
  type: typeof FETCH_SUCCESS
  payload: SubmissionOutcomesType[] | SubmissionHealthType[]
}

export interface ErrorType {
  type: typeof FETCH_FAILED
  payload: string
}

export type SubmissionsActionTypes =
  | GetSubmissionsAction
  | GetSubmissionsActionSucessType
  | ErrorType

/**
 * Action Handlers
 */
export const fetchSubmissions = (payload): GetSubmissionsAction => ({
  type: FETCH_REQUEST,
  payload,
})

export const getQueryByGlobalFilters = globalFilters => {
  const assetAreas = globalFilters.assetAreas
  const wells = globalFilters.wells
  const operatingAreas = globalFilters.operatingAreas
  const wellOperatorRuns = globalFilters.wellOperatorRuns
  const facilities = globalFilters.facilities
  const wellDevelopmentPackages = globalFilters.wellDevelopmentPackages

  const assetAreasMatchExpressions = assetAreas.map(assetArea => {
    return {
      match: {
        assetArea,
      },
    }
  })

  const wellsMatchExpressions = wells.map(well => {
    return {
      match: {
        wellId: well,
      },
    }
  })

  const operatingAreaMatchExpressions = operatingAreas.map(operatingArea => {
    return {
      match: {
        operatingArea,
      },
    }
  })

  const wellOperatorRunMatchExpressions = wellOperatorRuns.map(wellOperatorRun => {
    return {
      match: {
        wellOperatorRun,
      },
    }
  })

  const facilitiesMatchExpressions = facilities.map(gpf => {
    return {
      match: {
        gpf,
      },
    }
  })

  const wellDevelopmentPackagesMatchExpressions = wellDevelopmentPackages.map(
    wellDevelopmentPackage => {
      return {
        match: {
          wellDevelopmentPackage,
        },
      }
    }
  )

  const m = [
    assetAreasMatchExpressions,
    wellsMatchExpressions,
    operatingAreaMatchExpressions,
    wellOperatorRunMatchExpressions,
    facilitiesMatchExpressions,
    wellDevelopmentPackagesMatchExpressions,
  ]
    .filter(e => e.length > 0)
    .map(e => {
      return {
        bool: {
          should: e,
        },
      }
    })

  if (m.length === 0) {
    return null
  }

  const x = {
    bool: {
      must: m,
    },
  }

  return x
}

import * as ActionTypes from '../../actions/actionTypes'

const initialState: any = {
  byStatus: {},
  totalByStatus: {},
  isTasksLookUpPending: false,
}

const dashboardTasksReducer = (state: any = initialState, action): any => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_OPENSEARCH_TASKS: {
      return {
        ...state,
        isTasksLookUpPending: true,
      }
    }

    case ActionTypes.GET_DASHBOARD_TASKS_SUCCESS: {
      return {
        ...state,
        byStatus: {
          ...state.byStatus,
          ...action.payload.tasksByStatus,
        },
        totalByStatus: {
          ...state.totalByStatus,
          ...action.payload.totalTasksByStatus,
        },
        isTasksLookUpPending: false,
      }
    }

    case ActionTypes.CLEAR_DASHBOARD_TASKS: {
      return {
        ...state,
        byStatus: {},
      }
    }

    default:
      return state
  }
}

export default dashboardTasksReducer

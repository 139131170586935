import { sortBy } from 'lodash'

import { WellReviewType } from '../../types'
import {
  FETCH_SUCCESS,
  POST_REQUEST,
  POST_SUCCESS,
  FETCH_WELL_REVIEWES_REQUEST,
  FETCH_WELL_REVIEWES_FAILURE,
  FETCH_WELL_REVIEWES_SUCCESS,
} from '../../actions/wellReviews'
import { FETCH_REQUEST } from '../../actions/oer'

export interface WellReviewState {
  nextId?: string
  items: WellReviewType[]
  isWellReviewLookUpPending: boolean
}

export interface WellReviewByWellState {
  nextId?: string
  items: WellReviewType[]
  isWellReviewByWellLookUpPending: boolean
}

const initialState: WellReviewByWellState = {
  items: [],
  isWellReviewByWellLookUpPending: false,
}

const wellReviewByWellReducer = (
  state: WellReviewByWellState = initialState,
  action
): WellReviewByWellState => {
  switch (action.type) {
    case POST_REQUEST:
    case FETCH_WELL_REVIEWES_REQUEST: {
      return {
        ...state,
        isWellReviewByWellLookUpPending: true,
      }
    }

    case FETCH_WELL_REVIEWES_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload, 'createdAt').reverse(),
        isWellReviewByWellLookUpPending: false,
      }
    }

    case POST_SUCCESS: {
      return {
        ...state,
        items: [...state.items, action.payload],
        isWellReviewByWellLookUpPending: false,
      }
    }

    default:
      return state
  }
}

export default wellReviewByWellReducer

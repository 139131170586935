import { all, call, put, takeLatest, takeEvery } from '@redux-saga/core/effects'

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_WELL_REVIEWES_REQUEST,
  FETCH_WELL_REVIEWES_SUCCESS,
  POST_REQUEST,
  POST_SUCCESS,
  GetWellReviewsAction,
  GetWellReviewsByWellAction,
} from '../../actions/wellReviews'
import { GET_WELLS } from '../../actions/actionTypes'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'
import { apiUrl, postWellReview } from '../../api/wellReviews'
import { WELL_TABLE_ATTRIBUTES } from '../../constants'
import { FetchWellsContext } from '../../actions/wells'

function* getWellReviewsSaga({ payload: request = {} }: GetWellReviewsAction) {
  try {
    const response = yield call(apiGetter, apiUrl, { ...request })
    yield put({ type: FETCH_SUCCESS, payload: response })
  } catch (error) {
    yield all([put(enqueueErrorMessage({ message: 'Failed to load Well Reviews', error }))])
  }
}

function* getWellReviewsByWellSaga({ payload: request }: GetWellReviewsByWellAction) {
  try {
    const response = yield call(apiGetter, apiUrl, { ...request })
    yield put({ type: FETCH_WELL_REVIEWES_SUCCESS, payload: response })
  } catch (error) {
    yield all([
      put(
        enqueueErrorMessage({
          message: `Failed to load Well Reviews By Well - ${request?.wellId}`,
          error,
        })
      ),
    ])
  }
}

function* postWellReviewSaga(action) {
  const data = { ...action.payload }
  try {
    const response = yield call(postWellReview, data)

    yield all([
      put({
        type: POST_SUCCESS,
        payload: [response.data.body][0],
      }),
      put({
        type: GET_WELLS,
        payload: { attributes: WELL_TABLE_ATTRIBUTES, filters: { wellId: [data.wellId] } },
        context: FetchWellsContext.WellDetails,
      }),
    ])
  } catch (error) {
    yield all([
      put(
        enqueueErrorMessage({
          message: 'Failed to create Well Review',
          error,
        })
      ),
    ])
  }
}

function* watchSagas() {
  yield takeEvery(FETCH_REQUEST, getWellReviewsSaga)
  yield takeEvery(FETCH_WELL_REVIEWES_REQUEST, getWellReviewsByWellSaga)
  yield takeLatest(POST_REQUEST, postWellReviewSaga)
}

export const wellReviewSagas = [watchSagas]

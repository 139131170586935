import React from 'react'
import { Options } from 'material-table'

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const DATE_TIME_ISO = 'YYYY-MM-DD HH:mm:ss'
export const DATE_ISO = 'YYYY-MM-DD'

export const DEFAULT_WOR = 'Default'

export const VIS_TYPES = {
  PROD_SNAPSHOT: {
    name: 'prodSnapshot',
    link: 'https://opsvision.igap.int.origin.com.au/PIVision/PB/#/PBDisplays/Well - PN?currentelement=\\\\BCCRPIAF02\\IGwellsGeneric\\All\\Well Generic\\WELL_ID&starttime=-2&endtime=2',
  },
}

export const ROD_PUMP = 'Rod Pump'
export const PCP_PUMP = 'PCP'

export const FIN_YEAR_PREFIX = 'fin'

export const TASK_TYPE_EDIT_ROLE = ['WPA_PRODUCTION_ENGINEER', 'WPA_WELL_PERFORMANCE_TECHNICIAN']
export const WOR_CONFIG_EDIT_ROLE = ['WPA_PRODUCTION_ENGINEER', 'WPA_WELL_PERFORMANCE_TECHNICIAN']
export const WELL_REVIEW_EDIT_ROLE = ['WPA_PRODUCTION_ENGINEER', 'WPA_WELL_PERFORMANCE_TECHNICIAN']

export const VIEWS = {
  UNKNOWN: 'unknown',
  DASHBOARD: 'dashboard',
  TASKS: 'tasks',
  FILTERS: 'filters',
  WATCHLISTS: 'watch-lists',
  WELLLISTS: 'well-lists',
}

export const STATUS = {
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  DISREGARDED: 'Disregarded',
  EXPIRED: 'Expired',
  ESCALATED: 'Escalated',
  SUPERSEDED: 'Superseded',
  BENEFIT: 'BENEFIT',
  CLOSED: 'Closed',
}

export const TASK_TABS = {
  ASSIGNED: 0,
  TO_DO: 1,
  ESCALATED: 2,
  CLOSED: 3,
  LAPSED: 4,
  SUSPENSIONS: 5,
  BENEFITS: 6,
}

export const TABS = {
  TASKS: 'task-tabs',
  WELL_DETAILS: 'well-details-tab',
  DASHBOARD: 'dashboard-tab',
}

export const WELL_TABLE_ATTRIBUTES = [
  'wellId',
  'assetArea',
  'facility',
  'operatingArea',
  'wellOperatorRun',
  'wellHeadType',
  'wellOperationalStatus',
  'pumpSpeedActualRPM',
  'gasRateAvg1hGJ',
  'gasRateGasDayGJ',
  'gasSystemCapacityVarianceGJ',
  'gasOperationalVarianceGJ',
  'gasOperationalTargetGJ',
  'gasSystemCapacityGJ',
  'waterRateGasDayM3',
  'waterFlowMagFromSeparatorAvg1hM3',
  'tubingFlowMeterAvg1hM3',
  'downholeGaugeFluidLevel',
  'workoverType',
  'workoverDate',
  'actionOwner',
  'artificialLiftType',
  'pwrtRecoveryStatus',
  'pwrtRecoveryStatusDays',
  'lastReviewDate',
  'nextReviewDate',
  'wellSiteCode',
  'alsCode',
  'wellSiteCodes',
  'alsCodes',
  'tdWEC_Mode',
  'tdTag',
  'tdMethod',
  'tdStatus',
  'tdFailureRisk',
  'daysSincePostTurnDown',
  'subSurfaceLiftType',
  'separatorBypassInstalled',
  'sspInstalled',
  'WECMode',
  'piFCVPositionFeedback',
  'piSeparatorLevel',
  'piWECPCPEfficiencyAverage1h',
  'piSurfaceTransferPumpControllerMode',
  'piSeparatorGasPressure',
  'piWellheadSkidType',
  'piWaterGatheringPressure',
  'piGasGatheringPressure',
  'piPowerSupplyType',
  'piPumpTorqueAverage1h',
  'piCasingPressureRateofBleedDown',
  'piWECPCPTheoreticalPumpDisplacement',
  'wellMaintenanceStatus',
  'wellDevelopmentPackage',
  'designatedProductionEngineer',
  'wellSiteSLT',
  'alsSLT',
  'pendingIntervention',
  'openTasks',
  'assignedTasks',
  'optimalFluidLevel',
  'wecPumpMaxSpeedSetpoint',
  'casingPressureControlActingOnFcvSetpoint',
]

export const TABLE_DEFAULT_PROPS: { style: React.CSSProperties; options: Options<{}> } = {
  style: {
    color: '#707070',
    fontWeight: 600,
  },

  options: {
    addRowPosition: 'first',
    emptyRowsWhenPaging: false,
    padding: 'dense',
    pageSize: 25,
    pageSizeOptions: [25, 50, 100],
    thirdSortClick: false,
    maxBodyHeight: 'calc(100vh - 350px)',
    headerStyle: {
      flexDirection: 'row', // override flex row-reverse direction for numeric fields
      paddingRight: 16,
      color: '#fff',
      backgroundColor: '#F84516',
      fontWeight: 600,
      textAlign: 'center',
    },

    // NOTE: cellStyle - seems an undocumented prop
    // @ts-ignore
    cellStyle: {
      textAlign: 'center',
    },
  },
}

export const LINK_CELL_STYLE = {
  maxWidth: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export const LEVER_DEFAULT_VALUES = {
  Production: 'Production',
  Reliability: 'Reliability',
  Neutral: 'Neutral',
  Decarbonisation: 'Decarbonisation',
}

export const ERROR_MESSAGES = {
  STABILISATION_PERIOD: 'Please enter a number 0-9999',
  PRIORITY: 'Please enter a number 0-1000',
  REQUIRED: 'Required field',
  UNIQUE: 'Must be unique',
}

export const PWRT_STATUSES = {
  EXCLUDED: 'Excluded',
  RECOVERED: 'Recovered',
  IN_RECOVERY: 'In Recovery',
  FAILED: 'Failed',
  AWAITING_RESTART: 'Awaiting Restart',
}

import { call, put, takeLatest } from '@redux-saga/core/effects'

import { FETCH_REQUEST, FETCH_SUCCESS } from '../../actions/roles'
import { enqueueErrorMessage } from '../../actions/notification'
import axios from 'axios'

const getRoles = async () => {
  return await axios.get('/roles')
}

function* getRolesSaga() {
  try {
    const response = yield getRoles()
    yield put({ type: FETCH_SUCCESS, payload: response.data.body.data })
  } catch (error) {
    yield put(enqueueErrorMessage({ message: 'Failed to load Roles', error }))
  }
}

function* watchSagas() {
  yield takeLatest(FETCH_REQUEST, getRolesSaga)
}

export const rolesSagas = [watchSagas]

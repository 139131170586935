import { filtersMeta, labelToRole, filtersMetaForTaskTableByWellId } from '../../../utils'

export const getQueryByLocalFilters = (localFilters, wellId) => {
  const func = (key, filterValues) => {
    const getRoleValue = roleLabel => {
      return labelToRole(roleLabel)
    }
    if (filterValues.length === 0) {
      return null
    }
    const condShouldArray = filterValues.map(value => {
      return {
        match: {
          [key]: key === 'role' ? getRoleValue(value) : value,
        },
      }
    })
    const mustExpr = {
      bool: {
        minimum_should_match: 1,
        should: condShouldArray,
      },
    }
    return mustExpr
  }

  const selectedFiltersMeta = wellId ? filtersMetaForTaskTableByWellId : filtersMeta
  const result = selectedFiltersMeta
    .map(filterMeta => {
      return func(filterMeta.title, localFilters[filterMeta.position])
    })
    .filter(r => r !== null)

  return {
    bool: {
      must: result,
    },
  }
}

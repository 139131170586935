/**
 * Actions
 */

export const FETCH_REQUEST = 'well-review/FETCH_REQUEST'
export const FETCH_SUCCESS = 'well-review/FETCH_SUCCESS'
export const FETCH_FAILURE = 'well-review/FETCH_FAILURE'
export const POST_REQUEST = 'well-review/POST_REQUEST'
export const POST_SUCCESS = 'well-review/POST_SUCCESS'
export const POST_FAILURE = 'well-review/POST_FAILURE'

export const FETCH_WELL_REVIEWES_REQUEST = 'well-review/FETCH_WELL_REVIEWES_REQUEST'
export const FETCH_WELL_REVIEWES_SUCCESS = 'well-review/FETCH_WELL_REVIEWES_SUCCESS'
export const FETCH_WELL_REVIEWES_FAILURE = 'well-review/FETCH_WELL_REVIEWES_FAILURE'

/**
 * Types
 */

export interface GetWellReviewsAction {
  type: typeof FETCH_REQUEST
  payload: { wellId?: string } // to be removed from here
}

export interface GetWellReviewsByWellAction {
  type: typeof FETCH_WELL_REVIEWES_REQUEST
  payload: { wellId: string }
}

export interface PostWellReviewAction {
  type: typeof POST_REQUEST
  payload: object
}

/**
 * Action Handlers
 */

export const getWellReviewsAction = (request): GetWellReviewsAction => ({
  type: FETCH_REQUEST,
  payload: request,
})

export const getWellReviewsByWellAction = (request): GetWellReviewsByWellAction => ({
  type: FETCH_WELL_REVIEWES_REQUEST,
  payload: request,
})

export const postWellReviewAction = (data): PostWellReviewAction => ({
  type: POST_REQUEST,
  payload: {
    ...data,
  },
})

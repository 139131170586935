import { GET_WELLS_AGGREGATION, GET_WELLS_AGGREGATION_SUCCESS } from '../../actions/actionTypes'

export interface WellAggregationState {
  aggs: object
  isLoading: boolean
}

const initialState: WellAggregationState = {
  aggs: {},
  isLoading: false,
}

const wellAggregationsReducer = (
  state: WellAggregationState = initialState,
  { type, payload }
): WellAggregationState => {
  switch (type) {
    case GET_WELLS_AGGREGATION: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case GET_WELLS_AGGREGATION_SUCCESS: {
      const aggs = payload.data.body || []

      return {
        aggs,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default wellAggregationsReducer

import { QSStrategy } from './QSBuilder'

/**
 * Implements a query string building strategy which will add each key
 * of an object to the query string
 */
class ObjectKeyQSBuilderStrategy implements QSStrategy {
  /* eslint-disable class-methods-use-this */
  build(request): string {
    if (!request) {
      return ''
    }

    return Object.entries(request).reduce((path, [key, value], index) => {
      const seperator = index ? '&' : ''

      if (value !== null && value !== undefined) {
        return `${path}${seperator}${key}=${value}`
      }

      return path
    }, '')
  }
  /* eslint-enable */
}

export default ObjectKeyQSBuilderStrategy

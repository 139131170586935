import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
// eslint-disable-next-line
// import { composeWithDevTools } from 'redux-devtools-extension'

import configureRootReducer from '.'
import configureRootSaga from '../sagas'

export const history = createBrowserHistory()
const reactRouterMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const middleware = [reactRouterMiddleware, sagaMiddleware]

export const store = createStore(
  configureRootReducer(history),
  applyMiddleware(...middleware)
  // composeWithDevTools(applyMiddleware(...middleware))
)

sagaMiddleware.run(configureRootSaga)

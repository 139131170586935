import { all, call, put, takeEvery } from '@redux-saga/core/effects'

import { FETCH_REQUEST, FETCH_SUCCESS } from '../../actions/wellReviewClassifications'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'

const apiUrl = '/well-review-classifications'

function* getWellReviewClassificationsSaga() {
  try {
    const response = yield call(apiGetter, apiUrl)
    yield put({ type: FETCH_SUCCESS, payload: response })
  } catch (error) {
    yield all([
      put(enqueueErrorMessage({ message: 'Failed to load Well Review Classifications', error })),
    ])
  }
}

function* watchSagas() {
  yield takeEvery(FETCH_REQUEST, getWellReviewClassificationsSaga)
}

export const wellReviewClassificationSagas = [watchSagas]

/**
 * Actions
 */

export const FETCH_REQUEST = 'users/FETCH_REQUEST'
export const FETCH_SUCCESS = 'users/FETCH_SUCCESS'
export const FETCH_FAILED = 'users/FETCH_FAILED'

/**
 * Types
 */

export interface GetUsersPayload {
  group?: string
}

export interface GetUsersAction {
  type: typeof FETCH_REQUEST
  payload: GetUsersPayload
}

/**
 * Action Handlers
 */

export const getUsersAction = (payload?: GetUsersPayload): GetUsersAction => ({
  type: FETCH_REQUEST,
  payload,
})

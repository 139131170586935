/**
 * Actions
 */

export const FETCH_REQUEST = 'well-review-classification/FETCH_REQUEST'
export const FETCH_SUCCESS = 'well-review-classification/FETCH_SUCCESS'
export const FETCH_FAILURE = 'well-review-classification/FETCH_FAILURE'

/**
 * Types
 */

export interface GetWellReviewClassificationsAction {
  type: typeof FETCH_REQUEST
}

/**
 * Action Handlers
 */

export const getWellReviewClassificationsAction = (): GetWellReviewClassificationsAction => ({
  type: FETCH_REQUEST,
})

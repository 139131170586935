import querystring from 'querystring'

export const Qs = {
  /**
   * Returns parsed Query String object from browser url
   *
   * @returns { object } object containing all qs
   * e.g. { sort: 'priority:desc', tab: 0 }
   *
   */
  get() {
    const qs = window.location.search.slice(1) // remove '?'
    return querystring.parse(qs)
  },

  /**
   * Changes value in the browser URL bar and updates history without page refresh
   * After navigating away, you can return to this updated url by clicking Back button
   *
   * e.g. { tab: 2 } - will add/update a query string, e.g. ?key1=...&tab=2&anotherkey=...
   *
   * @param { object } value
   * @param { boolean } isResetQs
   *
   */

  set(newQs, isResetQs = false) {
    const qs = querystring.stringify({
      ...(!isResetQs && Qs.get()),
      ...newQs,
    })

    window.history.replaceState({}, '', `?${qs}`)
  },
}

export const filtersMeta = [
  {
    title: 'wellId',
    columnName: 'wellId',
    position: 1,
  },
  {
    title: 'priority',
    columnName: 'priority',
    position: 2,
  },
  {
    title: 'sourceSystem',
    columnName: 'sourceSystem',
    position: 3,
  },
  {
    title: 'role',
    columnName: 'role',
    position: 4,
  },
  {
    title: 'taskType',
    columnName: 'taskType',
    position: 5,
  },
]

export const filtersMetaForTaskTableByWellId = [
  {
    title: 'priority',
    columnName: 'priority',
    position: 0,
  },
  {
    title: 'sourceSystem',
    columnName: 'sourceSystem',
    position: 1,
  },
  {
    title: 'role',
    columnName: 'role',
    position: 2,
  },
  {
    title: 'taskType',
    columnName: 'taskType',
    position: 3,
  },
]

export const getSavedFilterList = () => {
  const savedFilter = Qs.get().filter as string
  const filterList = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
  if (savedFilter) {
    const colFilters = savedFilter.split(';')

    for (let i = 0; i < colFilters.length; i++) {
      const item = colFilters[i]
      const fmItem = filtersMeta.find(fm => item.includes(fm.columnName))

      const parts = item.split(':')
      const columnFilterList = parts[1]?.split(',')
      filterList[fmItem?.position] = columnFilterList
    }
  }

  return filterList
}

export const getSavedSortOrder = () => {
  // const sortBy = Qs.get()?.sortBy
  const obj = Qs.get() || {}
  const key = Object.keys(obj).find(k => k.includes('sortBy'))
  const sortBy = Qs.get()?.[key]

  const sortOrder = {
    name: (sortBy as string)?.split(':')[0] || 'priority',
    direction: (sortBy as string)?.split(':')[1] || 'desc',
  }

  return sortOrder
}

import { TaskTypesType } from '../types'

/**
 * Actions
 */

export const FETCH_REQUEST = 'taskType/FETCH_REQUEST'
export const FETCH_SUCCESS = 'taskType/FETCH_SUCCESS'
export const FETCH_FAILED = 'taskType/FETCH_FAILED'
export const CREATE_REQUEST = 'taskType/CREATE_REQUEST'
export const CREATE_SUCCESS = 'taskType/CREATE_SUCCESS'
export const CREATE_FAILED = 'taskType/CREATE_FAILED'
export const UPDATE_REQUEST = 'taskType/UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'taskType/UPDATE_SUCCESS'
export const UPDATE_FAILED = 'taskType/UPDATE_FAILED'
export const DELETE_REQUEST = 'taskType/DELETE_REQUEST'
export const DELETE_SUCCESS = 'taskType/DELETE_SUCCESS'
export const DELETE_FAILED = 'taskType/DELETE_FAILED'

/**
 * Types
 */

export interface GetTaskTypesAction {
  type: typeof FETCH_REQUEST
}

export interface CreateTaskTypesAction {
  type: typeof CREATE_REQUEST
  payload: TaskTypesType
}

export interface UpdateTaskTypesAction {
  type: typeof UPDATE_REQUEST
  payload: TaskTypesType
}

export interface DeleteTaskTypesAction {
  type: typeof DELETE_REQUEST
  payload: string
}

/**
 * Action Handlers
 */

export const getTaskTypesAction = (): GetTaskTypesAction => ({
  type: FETCH_REQUEST,
})

export const createTaskTypesAction = (payload: TaskTypesType): CreateTaskTypesAction => ({
  type: CREATE_REQUEST,
  payload,
})

export const updateTaskTypesAction = (payload: TaskTypesType): UpdateTaskTypesAction => ({
  type: UPDATE_REQUEST,
  payload,
})

export const deleteTaskTypesAction = (taskTypeId: string): DeleteTaskTypesAction => ({
  type: DELETE_REQUEST,
  payload: taskTypeId,
})

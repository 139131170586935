import { sortBy } from 'lodash'

import { WellReviewType } from '../../types'
import { FETCH_REQUEST, FETCH_SUCCESS } from '../../actions/wellReviews'

export interface WellReviewState {
  nextId?: string
  items: WellReviewType[]
  isWellReviewLookUpPending: boolean
}

export interface WellReviewByWellState {
  nextId?: string
  items: WellReviewType[]
  isWellReviewByWellLookUpPending: boolean
}

const initialState: WellReviewState = {
  items: [],
  isWellReviewLookUpPending: false,
}

const wellReviewReducer = (state: WellReviewState = initialState, action): WellReviewState => {
  switch (action.type) {
    case FETCH_REQUEST: {
      return {
        ...state,
        isWellReviewLookUpPending: true,
      }
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload, 'createdAt').reverse(),
        isWellReviewLookUpPending: false,
      }
    }

    default:
      return state
  }
}

export default wellReviewReducer

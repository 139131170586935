import { WellReviewClassificationType } from '../../types'
import { FETCH_SUCCESS } from '../../actions/wellReviewClassifications'

export interface WellReviewClassificationState {
  nextId?: string
  items: WellReviewClassificationType[]
}

const initialState: WellReviewClassificationState = {
  items: [],
}

const wellReviewClassificationReducer = (
  state: WellReviewClassificationState = initialState,
  action
): WellReviewClassificationState => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      return {
        ...state,
        items: action.payload,
      }
    }

    default:
      return state
  }
}

export default wellReviewClassificationReducer

export const getQueryByCompletedAt = (completedAt: any) => {
  return {
    range: {
      completedAt: {
        gte: completedAt.from,
        lte: completedAt.to,
      },
    },
  }
}

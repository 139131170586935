import { sortBy } from 'lodash'

import { UserType } from '../../types'
import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILED } from '../../actions/users'

export interface UsersState {
  items: UserType[]
  isUserLookupPending: boolean
}

const initialState: UsersState = {
  items: [],
  isUserLookupPending: false,
}

const usersReducer = (state: UsersState = initialState, action): UsersState => {
  switch (action.type) {
    case FETCH_REQUEST: {
      return {
        ...state,
        items: [],
        isUserLookupPending: true,
      }
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload, 'displayName'),
        isUserLookupPending: false,
      }
    }

    case FETCH_FAILED: {
      return {
        ...state,
        isUserLookupPending: false,
      }
    }

    default:
      return state
  }
}

export default usersReducer

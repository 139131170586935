export const getQueryByUpdatedAt = (updatedAt: any) => {
  return {
    range: {
      updatedAt: {
        gte: updatedAt.from,
        lte: updatedAt.to,
      },
    },
  }
}

import * as ActionTypes from './actionTypes'
import {
  GetTasksAction,
  PostNotesAction,
  UpdateTaskAction,
  CreateTaskAction,
  GetNotesForTasksAction,
  GetTaskAction,
} from './tasksTypes'

export const fetchTask = (request): GetTaskAction => ({
  type: ActionTypes.GET_TASK,
  payload: request,
})

export const fetchTasks = (request, silent = false): GetTasksAction => ({
  type: silent ? ActionTypes.GET_TASKS_SILENT : ActionTypes.GET_TASKS,
  payload: request,
})

export const fetchOpenSearchTasks = (request, silent = false): GetTasksAction => ({
  type: silent ? ActionTypes.GET_TASKS_SILENT : ActionTypes.GET_OPENSEARCH_TASKS,
  payload: request,
})

export const fetchOpenSearchWellsByOpenTasks = (request, silent = false): GetTasksAction => ({
  type: ActionTypes.GET_WELLS_BY_OPENTASKS,
  payload: request,
})

export const fetchOpenSearchTasksByWell = (request, silent = false): GetTasksAction => ({
  type: silent ? ActionTypes.GET_TASKS_SILENT : ActionTypes.GET_OPENSEARCH_TASKS_BY_WELL,
  payload: request,
})

export const clearOpenSearchTasks = (): GetTasksAction => ({
  type: ActionTypes.CLEAR_OPENSEARCH_TASKS,
})

export const fetchDashboardOpenSearchTasks = (request, silent = false): GetTasksAction => ({
  type: silent ? ActionTypes.GET_TASKS_SILENT : ActionTypes.GET_DASHBOARD_OPENSEARCH_TASKS,
  payload: request,
})

export const clearDashboardOpenSearchTasks = (silent = false): any => ({
  type: silent ? ActionTypes.GET_TASKS_SILENT : ActionTypes.CLEAR_DASHBOARD_TASKS,
})

export const fetchNotesForTask = (taskId): GetNotesForTasksAction => ({
  type: ActionTypes.GET_NOTES_FOR_TASK,
  payload: taskId,
})

export const postNotes = (data): PostNotesAction => ({
  type: ActionTypes.POST_NOTES,
  payload: {
    ...data,
  },
})

export const updateTask = (data): UpdateTaskAction => ({
  type: ActionTypes.UPDATE_TASK,
  payload: {
    ...data,
  },
})

export const createTask = (data): CreateTaskAction => ({
  type: ActionTypes.CREATE_TASK,
  payload: {
    ...data,
  },
})

import { OpUnitType } from 'dayjs'
import { DATE_TIME_FORMAT } from '../constants'
import { dayjs } from '.'

/**
 * formatDate
 * Format ISO date, e.g. 2020-07-16T13:59:23+10:00 -> 16/07/2020 13:59:23
 *
 * @param { string } date
 *
 */
export const formatDate = (date: any, format: string = DATE_TIME_FORMAT): string =>
  date ? dayjs(date).format(format) : ''

/**
 * getFinYearDates
 * Returns dates of the current or past financial year (e.g. 01/07/21 - 30/06/22)
 *
 * @param { number } yearsAgo
 *
 * @returns { from, to } dayjs object
 *
 */
export const getFinYearDates = (yearsAgo = 0) => {
  // yearsAgo = 0 -> this fin year
  const deltaFrom = dayjs().quarter() >= 3 ? 0 + yearsAgo : 1 + yearsAgo
  const deltaTo = deltaFrom - 1

  const from = dayjs().quarter(3).startOf('quarter').subtract(deltaFrom, 'year')
  const to = dayjs().quarter(2).endOf('quarter').subtract(deltaTo, 'year')

  return { from, to }
}

/**
 * getDateRange
 * Creates a date range based on the interval
 *
 * @param { dayjsObject} fromDate
 * @param { dayjsObject} toDate
 * @param { string } interval e.g. 'hours', 'days', 'weeks', etc... see dayjs docs
 *
 * @returns { array } range of dayjs objects beginning from fromDate and ending with toDate
 *
 * @example
 * const from = dayjs('2021-01-20')
 * const to = dayjs('2021-01-30')
 * getDateRange(from, to, 'days').map(d => d.format())
 *
 * -> ['2021-01-20', '2021-01-21',...'2021-01-30']
 *
 */
export const getDateRange = (fromDate, toDate, interval = 'days' as OpUnitType) => {
  const range = []
  const diff = Math.abs(toDate.diff(fromDate, interval)) + 1

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < diff; i++) {
    range.push(dayjs(fromDate).add(i, interval))
  }

  return range
}

/**
 * getInterval
 * Returns correct interval and date format based on supplied number of days
 * This is used to choose the correct chart scales in the Dashboard cards
 *
 * @param { number } days number of days
 *
 * @returns { interval, format }
 *
 */
export const getInterval = days => {
  let interval = 'Days'
  let format = 'MM-DD'

  if (days <= 1) {
    interval = 'Hours'
    format = 'DD-HH'
  } else if (days <= 31) {
    interval = 'Days'
    format = 'MM-DD'
  } else if (days > 31 && days <= 366 * 3) {
    interval = 'Months'
    format = 'YYYY-MM'
  } else if (days > 366 * 3) {
    interval = 'Years'
    format = 'YYYY'
  }

  return { interval, format }
}

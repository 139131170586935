import { STATUS } from '../../../constants'
import { getQueryByStatus } from './getQueryByStatus'

const { ESCALATED, CLOSED } = STATUS

export const getQueryByStatuses = (statuses: string[], currentUserEmail: string) => {
  const expressions = statuses.map(status => getQueryByStatus(status, currentUserEmail))

  return {
    bool: {
      should: expressions,
    },
  }
}

import { STATUS } from '../../../constants'

const restrictedRoles = ['WPA_DATA_SCIENTIST', 'WPA_TRIAL_USERS']

export const getQueryByMyRoles = (
  currentUserRoles: string[],
  status: string,
  myRolesOnly: boolean
): QueryByMyRoles | undefined => {
  const field =
    status?.toLowerCase() === STATUS.ESCALATED.toLowerCase() ? 'escalatedByRoles' : 'role'

  const clause = myRolesOnly ? 'should' : 'must_not'

  const roles = myRolesOnly
    ? currentUserRoles
    : restrictedRoles.filter(role => !currentUserRoles.includes(role))

  if (roles.length === 0) return undefined

  return {
    bool: {
      [clause]: roles.map(role => ({
        match: {
          [field]: role,
        },
      })),
    },
  }
}

export type QueryByMyRoles = {
  bool: {
    [key in 'must_not' | 'should']?: {
      match: {
        [key in 'role' | 'escalatedbyRoles']?: string
      }[]
    }[]
  }
}

import { all, call, put, takeLatest } from '@redux-saga/core/effects'

import {
  GET_FILTERS,
  GET_FILTERS_SUCCESS,
  GET_FILTERS_ERROR,
  POST_FILTERS,
  POST_FILTERS_SUCCESS,
  POST_FILTERS_ERROR,
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
} from '../../actions/actionTypes'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'
import { postFilters } from '../../api/filters'

function* fetchFiltersSaga() {
  try {
    const response = yield call(apiGetter, '/user-preferences')

    yield put({ type: GET_FILTERS_SUCCESS, payload: response })
  } catch (error) {
    yield all([
      put({ type: GET_FILTERS_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Filters', error })),
    ])
  }
}

function* fetchLocationsSaga() {
  try {
    const response = yield call(apiGetter, '/locations')

    yield put({ type: GET_LOCATIONS_SUCCESS, payload: response })
  } catch (error) {
    yield all([
      put({ type: GET_LOCATIONS_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Locations', error })),
    ])
  }
}

function* postFiltersSaga(action) {
  const data = action.payload

  try {
    yield call(postFilters, data)
    yield put({ type: POST_FILTERS_SUCCESS, payload: data })
  } catch (error) {
    yield all([
      put({ type: POST_FILTERS_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to create Filters', error })),
    ])
  }
}

function* watchSagas() {
  yield takeLatest(GET_FILTERS, fetchFiltersSaga)
  yield takeLatest(POST_FILTERS, postFiltersSaga)
  yield takeLatest(GET_LOCATIONS, fetchLocationsSaga)
}

export const filtersSagas = [watchSagas]

import axios, { AxiosResponse } from 'axios'

export const apiUrl = '/tasks'
export const searchTasksApiUrl = '/search-tasks'
export const fetchDownholeDiagramsUrl = '/get-downhole-diagrams'

export const updateTask = (payload): Promise<AxiosResponse> => {
  const {
    taskId,
    note,
    action,
    status,
    role,
    sendToOpralog,
    taskDataAttributes,
    disregardedReasons,
    completedReasons,
    actionsTaken,
    completedNote,
    disregardedNote,
  } = payload

  const requestData = {
    taskId,
    updateNote: note,
    updateAction: action,
    status,
    taskDataAttributes,
    completedReasons,
    actionsTaken,
    completedNote,
    disregardedReasons,
    disregardedNote,
    ...(sendToOpralog !== undefined && { sendToOpralog }),
    ...(role && { role }),
  }
  return axios.patch(apiUrl, requestData)
}

export const createTask = (requestData): Promise<AxiosResponse> => {
  return axios.post(apiUrl, requestData)
}

export const searchTasks = (requestData): Promise<AxiosResponse> => {
  requestData['env'] = process.env.OPENSEARCH_ENV
  return axios.post(searchTasksApiUrl, requestData)
}

export const fetchDownholeDiagrams = (requestData): Promise<AxiosResponse> => {
  return axios.post(fetchDownholeDiagramsUrl, requestData)
}

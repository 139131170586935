import { RouterState } from 'connected-react-router'

import { NotificationStateType } from './notification'
import { AuthState } from '../actions/authTypes'
import { NotesForTaskState, TasksState } from '../actions/tasksTypes'
import { ConfigState } from './config'
import { FiltersState } from './filters'
import { RolesState } from './roles'
import { UsersState } from './users'
import { SubmissionStateType } from './submissions'
import { SuspensionStateType } from './suspensions'
import { TaskTypesState } from './taskTypes'
import { WatchListsState } from './watchLists'
import { WellListsState } from './wellLists'
import { WorsState } from './wors'
import { OERState } from './oer'
import { WellReviewState } from './wellReview'
import { WellReviewClassificationState } from './wellReviewClassification'
import { DownholeDiagramStateType } from './downholeDiagramsByWell'
import { WellAggregationState } from './wellAggregator'
import { WellTabsState } from './wellTabs'

export interface RootState {
  auth: AuthState
  config: ConfigState
  filters: FiltersState
  notes: NotesForTaskState
  notifications: Array<NotificationStateType>
  oer: OERState
  roles: RolesState
  users: UsersState
  router: RouterState
  submissions: SubmissionStateType
  suspensions: SuspensionStateType
  tasks: TasksState
  tasksByWell: TasksState
  dashboardTasks: any
  taskTypes: TaskTypesState
  watchLists: WatchListsState
  wellAggregator: WellAggregationState
  wellLists: WellListsState
  wors: WorsState
  wellReviews: WellReviewState
  wellReviewClassifications: WellReviewClassificationState
  downholeDiagrams: DownholeDiagramStateType
  wellTabs: WellTabsState
}

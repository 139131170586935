import { WorType } from '../types'

/**
 * Actions
 */

export const FETCH_REQUEST = 'wors/FETCH_REQUEST'
export const FETCH_SUCCESS = 'wors/FETCH_SUCCESS'
export const FETCH_FAILED = 'wors/FETCH_FAILED'
export const CREATE_REQUEST = 'wors/CREATE_REQUEST'
export const CREATE_SUCCESS = 'wors/CREATE_SUCCESS'
export const CREATE_FAILED = 'wors/CREATE_FAILED'
export const UPDATE_REQUEST = 'wors/UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'wors/UPDATE_SUCCESS'
export const UPDATE_FAILED = 'wors/UPDATE_FAILED'

/**
 * Types
 */

export interface GetWorsPayload {
  attributes?: string
  wellIds?: string
}
export interface GetWorsAction {
  type: typeof FETCH_REQUEST
}

export interface CreateWorsAction {
  type: typeof CREATE_REQUEST
  payload: WorType
}

export interface UpdateWorsAction {
  type: typeof UPDATE_REQUEST
  payload: WorType
}

/**
 * Action Handlers
 */

export const getWorsAction = (): GetWorsAction => ({
  type: FETCH_REQUEST,
})

export const createWorsAction = (payload: WorType): CreateWorsAction => ({
  type: CREATE_REQUEST,
  payload,
})

export const updateWorsAction = (payload: WorType): UpdateWorsAction => ({
  type: UPDATE_REQUEST,
  payload,
})

import { OERType } from '../types'

/**
 * Actions
 */

export const FETCH_REQUEST = 'oer/FETCH_REQUEST'
export const FETCH_SUCCESS = 'oer/FETCH_SUCCESS'
export const FETCH_FAILED = 'oer/FETCH_FAILED'

/**
 * Types
 */

export interface GetOERActionType {
  type: typeof FETCH_REQUEST
  payload: { source?: string }
}

interface GetOERActionSucessType {
  type: typeof FETCH_SUCCESS
  payload: Array<OERType>
}

export interface ErrorType {
  type: typeof FETCH_FAILED
  payload: string
}

export type OERActionTypes = GetOERActionType | GetOERActionSucessType | ErrorType

/**
 * Action Handlers
 */

export const getOERAction = (source?): GetOERActionType => ({
  type: FETCH_REQUEST,
  payload: { source },
})

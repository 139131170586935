import { call, put, takeLatest, takeLeading } from '@redux-saga/core/effects'
import axios from 'axios'
import {
  GET_WELLS,
  GET_WELLS_AGGREGATION,
  GET_WELLS_AGGREGATION_SUCCESS,
  GET_WELLS_SUCCESS,
} from '../../actions/actionTypes'
import { GetWellsAction, GetWellsAggregationAction } from '../../actions/wells'
import { enqueueErrorMessage } from '../../actions/notification'

const searchUrl = '/search-wells'
const aggregationUrl = '/search-wells-aggregations'

function* getWellsSaga({ payload, context }: GetWellsAction) {
  try {
    const response = yield call(axios.post, searchUrl, payload)
    yield put({ type: GET_WELLS_SUCCESS, payload: response.data, context })
  } catch (error) {
    yield put(enqueueErrorMessage({ message: 'Failed to load Wells', error, context }))
  }
}

function* getWellAggregationsSaga({ payload }: GetWellsAggregationAction) {
  try {
    const response = yield call(axios.post, aggregationUrl, payload)
    yield put({ type: GET_WELLS_AGGREGATION_SUCCESS, payload: response })
  } catch (error) {
    yield put(enqueueErrorMessage({ message: 'Failed to load well aggregations', error }))
  }
}

function* watchSagas() {
  yield takeLatest(GET_WELLS, getWellsSaga)
  yield takeLatest(GET_WELLS_AGGREGATION, getWellAggregationsSaga)
}

export const wellListsSagas = [watchSagas]

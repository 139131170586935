import { SuspensionType } from '../types'

/**
 * Actions
 */

export const FETCH_REQUEST = 'suspensions/FETCH_REQUEST'
export const FETCH_SUCCESS = 'suspensions/FETCH_SUCCESS'
export const FETCH_FAILED = 'suspensions/FETCH_FAILED'
export const CREATE_REQUEST = 'suspensions/CREATE_REQUEST'
export const CREATE_SUCCESS = 'suspensions/CREATE_SUCCESS'
export const CREATE_FAILED = 'suspensions/CREATE_FAILED'
export const UPDATE_REQUEST = 'suspensions/UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'suspensions/UPDATE_SUCCESS'
export const UPDATE_FAILED = 'suspensions/UPDATE_FAILED'

/**
 * Types
 */

export interface GetSuspensionsActionType {
  type: typeof FETCH_REQUEST
  payload: { wellId?: string; taskType?: string }
}

export interface CreateSuspensionsActionType {
  type: typeof CREATE_REQUEST
  payload: { wellId: string; notes: string; taskType: string; from: string; to: string }
}

export interface UpdateSuspensionsActionType {
  type: typeof UPDATE_REQUEST
  payload: {
    sId: string
    wellId: string
    notes: string
    taskType: string
    from: string
    to: string
  }
}

interface GetSuspensionSucessType {
  type: typeof FETCH_SUCCESS
  payload: Array<SuspensionType>
}

interface CreateSuspensionSucessType {
  type: typeof CREATE_SUCCESS
  payload: SuspensionType
}

interface UpdateSuspensionSucessType {
  type: typeof UPDATE_SUCCESS
  payload: SuspensionType
}

export interface ErrorType {
  type: typeof FETCH_FAILED | typeof CREATE_FAILED | typeof UPDATE_FAILED
  payload: string
}

export type SuspensionActionTypes =
  | GetSuspensionsActionType
  | CreateSuspensionsActionType
  | UpdateSuspensionsActionType
  | GetSuspensionSucessType
  | CreateSuspensionSucessType
  | UpdateSuspensionSucessType
  | ErrorType

/**
 * Action Handlers
 */

export const fetchSuspensions = (wellId?, taskType?): GetSuspensionsActionType => ({
  type: FETCH_REQUEST,
  payload: { wellId, taskType },
})

export const createSuspension = (request): CreateSuspensionsActionType => ({
  type: CREATE_REQUEST,
  payload: {
    ...request,
  },
})

export const updateSuspension = (request): UpdateSuspensionsActionType => ({
  type: UPDATE_REQUEST,
  payload: {
    ...request,
  },
})

import { all, call, put, takeLatest, takeEvery } from '@redux-saga/core/effects'

import {
  DELETE_WELL_TAB,
  DELETE_WELL_TAB_SUCCESS,
  GET_WELL_TABS,
  GET_WELL_TABS_SUCCESS,
  UPSERT_WELL_TAB,
  UPSERT_WELL_TAB_SUCCESS,
} from '../../actions/actionTypes'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'
import { getApiUrl, deleteWellTab, upsertWellTab } from '../../api/wellTabs'
import { DeleteWellTabAction, UpsertWellTabAction } from '../../actions/wellTabs'

function* getWellTabsSaga() {
  try {
    const response = yield call(apiGetter, getApiUrl)
    yield put({ type: GET_WELL_TABS_SUCCESS, payload: response })
  } catch (error) {
    yield all([put(enqueueErrorMessage({ message: 'Failed to load Well tabs', error }))])
  }
}

function* upsertWellTabSaga(request: UpsertWellTabAction) {
  try {
    const response = yield call(upsertWellTab, request.payload)
    yield put({ type: UPSERT_WELL_TAB_SUCCESS, payload: response?.data?.body?.data })
  } catch (error) {
    yield all([
      put(
        enqueueErrorMessage({
          message: `Failed to create or update the well tab ${request?.payload?.name}`,
          error,
        })
      ),
    ])
  }
}

function* deleteWellTabSaga(request: DeleteWellTabAction) {
  try {
    const response = yield call(deleteWellTab, request.payload)
    yield put({ type: DELETE_WELL_TAB_SUCCESS, payload: response?.data?.body?.data })
  } catch (error) {
    yield all([
      put(
        enqueueErrorMessage({
          message: `Failed to delete the well tab ${request?.payload?.name}`,
          error,
        })
      ),
    ])
  }
}

function* watchSagas() {
  yield takeEvery(GET_WELL_TABS, getWellTabsSaga)
  yield takeEvery(UPSERT_WELL_TAB, upsertWellTabSaga)
  yield takeLatest(DELETE_WELL_TAB, deleteWellTabSaga)
}

export const wellTabSagas = [watchSagas]

/**
 * Actions
 */

export const FETCH_REQUEST = 'roles/FETCH_REQUEST'
export const FETCH_SUCCESS = 'roles/FETCH_SUCCESS'

/**
 * Types
 */

export interface GetRolesAction {
  type: typeof FETCH_REQUEST
}

/**
 * Action Handlers
 */

export const getRolesAction = (): GetRolesAction => ({
  type: FETCH_REQUEST,
})

import { sortBy } from 'lodash'

import { RolesType } from '../../types'
import { FETCH_SUCCESS } from '../../actions/roles'

export interface RolesState {
  items: RolesType[]
  users?: string[]
}

const initialState: RolesState = {
  items: [],
}

const rolesReducer = (state: RolesState = initialState, action): RolesState => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload.Items, 'displayName'),
        users: sortBy(action.payload.Users?.filter(u => u.includes('@'))),
      }
    }

    default:
      return state
  }
}

export default rolesReducer

import { sortBy } from 'lodash'

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILED,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILED,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
} from '../../actions/wors'
import { WorType } from '../../types'

export interface WorsState {
  items: WorType[]
  isWorsRequestPending: boolean
}

const initialState: WorsState = {
  items: [],
  isWorsRequestPending: false,
}

const worsReducer = (state: WorsState = initialState, action): WorsState => {
  switch (action.type) {
    case FETCH_REQUEST:
    case CREATE_REQUEST:
    case UPDATE_REQUEST: {
      return {
        ...state,
        isWorsRequestPending: true,
      }
    }

    case FETCH_FAILED:
    case CREATE_FAILED:
    case UPDATE_FAILED: {
      return {
        ...state,
        isWorsRequestPending: false,
      }
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload, 'wellOperatorRun'),
        isWorsRequestPending: false,
      }
    }

    case CREATE_SUCCESS: {
      return {
        ...state,
        items: [action.payload, ...state.items],
        isWorsRequestPending: false,
      }
    }

    case UPDATE_SUCCESS: {
      const updatedWor = action.payload

      const items = [...state.items]
      const itemIndex = items.findIndex(item => item.worId === updatedWor.worId)

      items[itemIndex] = { ...items[itemIndex], ...updatedWor }

      return {
        ...state,
        items,
        isWorsRequestPending: false,
      }
    }

    default:
      return state
  }
}

export default worsReducer

import { all, call, put, takeLatest } from '@redux-saga/core/effects'

import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILED, GetUsersAction } from '../../actions/users'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'

const apiUrl = '/users'

function* getUsersSaga({ payload }: GetUsersAction) {
  try {
    const response = yield call(apiGetter, apiUrl, payload)

    yield put({ type: FETCH_SUCCESS, payload: response })
  } catch (error) {
    yield all([
      put({ type: FETCH_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Users', error })),
    ])
  }
}

function* watchSagas() {
  yield takeLatest(FETCH_REQUEST, getUsersSaga)
}

export const usersSagas = [watchSagas]

import axios, { AxiosResponse } from 'axios'

import { TaskTypesType } from '../types'

export const apiUrl = '/task-types'

export const patchTaskTypes = (data: TaskTypesType): Promise<AxiosResponse> =>
  axios.patch(apiUrl, data)

export const postTaskTypes = (data: TaskTypesType): Promise<AxiosResponse> =>
  axios.post(apiUrl, data)

export const deleteTaskTypes = (taskTypeId: string): Promise<AxiosResponse> =>
  axios.delete(apiUrl, { data: { id: taskTypeId } })

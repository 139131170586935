import axios, { AxiosResponse } from 'axios'

import { WellTab } from '../types'

const apiUrl = '/well-tabs'
export const getApiUrl = '/well-tabs'

export const upsertWellTab = (data: WellTab): Promise<AxiosResponse> => axios.put(apiUrl, data)
export const deleteWellTab = (data: WellTab): Promise<AxiosResponse> =>
  axios.delete(`${apiUrl}?id=${encodeURIComponent(data.rangeKey)}`)

import axios from 'axios'
import { isString } from 'lodash'
import {
  all,
  call,
  put,
  race,
  select,
  takeLatest,
  takeLeading,
  takeEvery,
  delay,
} from '@redux-saga/core/effects'

import { enqueueErrorMessage } from '../../actions/notification'
import * as downholediagrams from '../../actions/downholeDiagrams'
import { apiGetter } from '../../api/apiGetter'
import { fetchDownholeDiagrams } from '../../api/tasks'

function* getDownholeDiagramsSaga(action: downholediagrams.GetDownholeDiagramsActionType) {
  const { payload: request } = action

  try {
    const apiUrl = `/get-downhole-diagrams`
    //const response = yield call(apiGetter, apiUrl, { ...request })
    const response = yield call(fetchDownholeDiagrams, request)

    yield put({
      type: downholediagrams.FETCH_SUCCESS,
      payload: { ...response.data.body, wellId: request.wellId },
    })
  } catch (error) {
    yield all([
      put({ type: downholediagrams.FETCH_FAILED, payload: error?.message || error }),
      put(
        enqueueErrorMessage({
          message: 'Failed to load downhole diagrams',
          error,
        })
      ),
    ])
  }
}

function* watchSagas() {
  yield takeEvery(downholediagrams.FETCH_REQUEST, getDownholeDiagramsSaga)
}

export const downholeDiagramsSagas = [watchSagas]

import { DATE_TIME_FORMAT, DATE_TIME_ISO } from '../constants'
import { dayjs } from '.'

/**
 * filterArray
 *
 * Filters given array by some filter functions (predicates)
 * For each array element, each predicate will be invoked with that element
 * The element will be included in the result if all predicates return true
 *
 * @param { Array } arr
 * @param { Array } predicates functions that return Boolean
 *
 * @returns { Array } filtered array
 *
 * @example filterArray(tasks.Completed, [updatedBy, updatedAt])
 * @example filterArray(tasks.Completed, [filterByUser('userName', 'updatedBy'), updatedAt])
 */
export const filterArray = (arr = [], predicates = []) =>
  arr.filter(el => predicates.reduce((result, predicate) => result && predicate(el), true))

/**
 * getFilterBy
 *
 * Returns a predicate function to be used in filterArray
 * The function will filter array elements by matching the userName
 * with value taken from the provided fieldName (e.g. 'updatedBy')
 *
 * @array
 * @param { string } userName
 * @param { string } fieldName
 *
 * @returns { Function } predicate function
 *
 */
export const getFilterByUser =
  (userName, fieldName = 'updatedBy') =>
  (task = {}): boolean => {
    const value = task[fieldName]

    const valueToCheck = Array.isArray(value) ? value.join().toLowerCase() : value.toLowerCase()
    return valueToCheck.includes(userName.toLowerCase())
  }

/**
 * getFilterByDate
 * Returns a predicate function to be used in filterArray
 * The function will filter array elements by checking that the date
 * in the provided fieldName is within the dateRange
 *
 * @param { object } dateRange e.g. { from: dayjsObject , to: dayjsObject}
 * @param { string } fieldName
 *
 * @returns { Function } predicate function
 *
 */
export const getFilterByDate =
  ({ from, to }, fieldName = 'updatedAt') =>
  (task = {}): boolean => {
    const dateToCheck = dayjs(task[fieldName], [DATE_TIME_FORMAT, DATE_TIME_ISO])

    return dateToCheck.isBetween(from, to, null, '[]')
  }

/**
 * Maps global filters to well filters.
 *
 * @param {object} globalFilters - The global filters object.
 * @returns {object} - The mapped well filters object.
 */
export const mapGlobalFilterToWellFilter = globalFilters => {
  const fieldMapping = {
    assetAreas: 'assetArea',
    operatingAreas: 'operatingArea',
    facilities: 'facility',
    wellOperatorRuns: 'wellOperatorRun',
    wellDevelopmentPackages: 'wellDevelopmentPackage',
    wells: 'wellId',
    designatedPEs: 'designatedProductionEngineer',
  }

  const mappedFilters = Object.keys(globalFilters).reduce((res, key) => {
    if (!fieldMapping[key] || !globalFilters[key] || globalFilters[key].length === 0) return res

    const newKey = fieldMapping[key]
    res[newKey] = globalFilters[key]
    return res
  }, {})

  return mappedFilters
}

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILED,
  DownholeDiagramsActionTypes,
} from '../../actions/downholeDiagrams'
import { DownholeDiagramsType } from '../../types'

export interface DownholeDiagramStateType {
  item: DownholeDiagramsType
  error: string
  isDownholeDiagramsPending: boolean
}

const initialState: DownholeDiagramStateType = {
  item: undefined,
  error: undefined,
  isDownholeDiagramsPending: false,
}

const downholeDiagramsReducer = (
  state: DownholeDiagramStateType = initialState,
  action: DownholeDiagramsActionTypes
): DownholeDiagramStateType => {
  switch (action.type) {
    case FETCH_FAILED: {
      return {
        ...state,
        error: action.payload,
        isDownholeDiagramsPending: false,
      }
    }

    case FETCH_REQUEST: {
      return {
        ...state,
        isDownholeDiagramsPending: true,
      }
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        item: action.payload,
        error: undefined,
        isDownholeDiagramsPending: false,
      }
    }

    default:
      return state
  }
}

export default downholeDiagramsReducer

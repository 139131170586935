import { DownholeDiagramsType } from '../types'

/**
 * Actions
 */

export const FETCH_REQUEST = 'downholeDiagrams/FETCH_REQUEST'
export const FETCH_SUCCESS = 'downholeDiagrams/FETCH_SUCCESS'
export const FETCH_FAILED = 'downholeDiagrams/FETCH_FAILED'

/**
 * Types
 */

export interface GetDownholeDiagramsActionType {
  type: typeof FETCH_REQUEST
  payload: { wellId: string }
}

interface GetDownholeDiagramsSuccessType {
  type: typeof FETCH_SUCCESS
  payload: DownholeDiagramsType
}

export interface ErrorType {
  type: typeof FETCH_FAILED
  payload: string
}

export type DownholeDiagramsActionTypes =
  | GetDownholeDiagramsActionType
  | ErrorType
  | GetDownholeDiagramsSuccessType

/**
 * Action Handlers
 */

export const getDownholeDiagramsAction = (wellId: string): GetDownholeDiagramsActionType => ({
  type: FETCH_REQUEST,
  payload: { wellId },
})

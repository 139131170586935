import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILED, OERActionTypes } from '../../actions/oer'
import { OERType } from '../../types'

export interface OERState {
  items: OERType[]
  isOERLookUpPending: boolean
}

const initialState: OERState = {
  items: [],
  isOERLookUpPending: false,
}

const worReducer = (state: OERState = initialState, action: OERActionTypes): OERState => {
  switch (action.type) {
    case FETCH_REQUEST: {
      return {
        ...state,
        items: [],
        isOERLookUpPending: true,
      }
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        isOERLookUpPending: false,
      }
    }
    case FETCH_FAILED: {
      return {
        ...state,
        isOERLookUpPending: false,
      }
    }

    default:
      return state
  }
}

export default worReducer

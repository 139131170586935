import { createMuiTheme } from '@material-ui/core/styles'
import deepOrange from '@material-ui/core/colors/deepOrange'
import amber from '@material-ui/core/colors/amber'

// https://v4.mui.com/customization/default-theme/

export default ({ darkMode }) =>
  createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: deepOrange,
      secondary: amber,
    },
    card: {
      background: darkMode ? '#303030' : '#f1f1f1',
    },
  })

declare module '@material-ui/core/styles' {
  interface Theme {
    card: {
      background: string
    }
  }

  // allow configuration using `createTheme()`
  interface ThemeOptions {
    card: {
      background: string
    }
  }
}

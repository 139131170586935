import { all, call, put, takeEvery } from '@redux-saga/core/effects'

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILED,
  GetSubmissionsAction,
} from '../../actions/submissions'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter, apiGet } from '../../api/apiGetter'
import { dayjs } from '../../utils'

const apiUrl = '/submissions'

function* getSubmissionsSaga({ payload }: GetSubmissionsAction) {
  const key = payload.key || 'outcomes'

  let response = []

  try {
    if (key === 'health') {
      const requestToday = {
        from: dayjs().startOf('day').format(),
        to: 'LATEST',
      }

      const response = yield call(apiGet, apiUrl, requestToday)
      const taskTypeStats = response?.body?.taskTypeStats?.map(item => item.data)

      return yield put({ type: FETCH_SUCCESS, payload: { health: taskTypeStats } })
    }

    response = yield call(apiGetter, apiUrl, payload)
    yield put({ type: FETCH_SUCCESS, payload: { outcomes: response[0]?.items } })
  } catch (error) {
    yield all([
      put({ type: FETCH_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Submissions', error })),
    ])
  }
}

function* watchSagas() {
  yield takeEvery(FETCH_REQUEST, getSubmissionsSaga)
}

export const submissionsSagas = [watchSagas]

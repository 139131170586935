import { History } from 'history'
import { combineReducers, Reducer } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import config from './config'
import filters from './filters'
import notes from './notes'
import notifications from './notification'
import oer from './oer'
import roles from './roles'
import users from './users'
import submissions from './submissions'
import suspensions from './suspensions'
import tasks from './tasks'
import dashboardTasks from './dashboardTasks'
import taskTypes from './taskTypes'
import watchLists from './watchLists'
import wellAggregator from './wellAggregator'
import wellLists from './wellLists'
import wors from './wors'
import wellReviews from './wellReview'
import wellReviewsByWell from './wellReviewByWell'
import wellReviewClassifications from './wellReviewClassification'
import tasksByWell from './tasksByWell'
import wellsByOpenTasks from './wellsByOpenTasks'
import downholeDiagrams from './downholeDiagramsByWell'
import wellTabs from './wellTabs'

const configureRootReducer = (history: History): Reducer =>
  combineReducers({
    auth,
    config,
    filters,
    notes,
    notifications,
    oer,
    roles,
    users,
    router: connectRouter(history),
    submissions,
    suspensions,
    tasks,
    dashboardTasks,
    taskTypes,
    watchLists,
    wellAggregator,
    wellLists,
    wors,
    wellReviews,
    wellReviewsByWell,
    wellReviewClassifications,
    tasksByWell,
    wellsByOpenTasks,
    downholeDiagrams,
    wellTabs,
  })

export default configureRootReducer

export * from './types'

export const getQueryBySearchText = (searchText: string) => {
  let shouldBlock = {
    minimum_should_match: 1,
    should: [],
  }

  const converted = parseInt(searchText, 10)
  if (!Number.isNaN(converted)) {
    shouldBlock.should.push({
      match: {
        priority: converted,
      },
    })
  }

  const expr = {
    value: `*${searchText}*`,
    case_insensitive: true,
  }

  shouldBlock.should.push({
    wildcard: {
      wellId: expr,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      sourceSystem: expr,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      role: expr,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      taskType: expr,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      description: expr,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      lastNote: expr,
    },
  })

  return {
    bool: shouldBlock,
  }
}

import moment from 'moment'
import { DATE_TIME_FORMAT } from '../constants'
import { dayjs, Qs } from '.'

export const compareStringsFunc = (order, obj1, obj2) => {
  const val1 = obj1
  const val2 = obj2
  if (!val1) {
    return -1 * (order === 'asc' ? 1 : -1)
  }
  if (!val2) {
    return 1 * (order === 'asc' ? 1 : -1)
  }
  return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1)
}

export const compareStringsObjs = order => {
  return (obj1, obj2) => {
    const val1 = obj1.data
    const val2 = obj2.data
    if (!val1) {
      return -1 * (order === 'asc' ? 1 : -1)
    }
    if (!val2) {
      return 1 * (order === 'asc' ? 1 : -1)
    }
    if (val1 === val2) {
      return compareStringsFunc(order, obj1.rowData[0], obj2.rowData[0])
    }
    return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1)
  }
}

export const compareStrings = (order, property, obj1, obj2, secondary: string = 'wellId') => {
  const val1 = obj1[property]
  const val2 = obj2[property]
  if (!val1) {
    return -1 * (order === 'asc' ? 1 : -1)
  }
  if (!val2) {
    return 1 * (order === 'asc' ? 1 : -1)
  }
  if (val1 === val2) {
    return compareStringsFunc(order, obj1[secondary], obj2[secondary])
  }
  return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1)
}

export const compareDates = (order: string, format: string) => {
  return (obj1, obj2) => {
    const leftDate = moment(obj1.position > obj2.position ? obj1.data : obj2.data, format, true)
    const rightDate = moment(obj1.position < obj2.position ? obj1.data : obj2.data, format, true)
    if (!leftDate.isValid()) {
      return -1 * (order === 'asc' ? 1 : -1)
    }
    if (!rightDate.isValid()) {
      return 1 * (order === 'asc' ? 1 : -1)
    }
    if (leftDate.isSame(rightDate)) {
      return compareStringsFunc(order, obj1.rowData[0], obj2.rowData[0])
    }
    const before = leftDate.isBefore(rightDate)
    return (before ? -1 : 1) * (order === 'asc' ? 1 : -1)
  }
}

export const compareDatesFunc = (
  order: string,
  format: string,
  property: string,
  obj1,
  obj2,
  secondary: string = 'wellId'
) => {
  const leftDate = moment(obj1[property], format, true)
  const rightDate = moment(obj2[property], format, true)
  if (!leftDate.isValid()) {
    return -1 * (order === 'asc' ? 1 : -1)
  }
  if (!rightDate.isValid()) {
    return 1 * (order === 'asc' ? 1 : -1)
  }
  if (leftDate.isSame(rightDate)) {
    return compareStrings(order, secondary, obj1, obj2)
  }
  const before = leftDate.isBefore(rightDate)
  return (before ? -1 : 1) * (order === 'asc' ? 1 : -1)
}

export const compareNumbers = order => {
  return (obj1, obj2) => {
    const val1 = parseInt(obj1.data, 10)
    const val2 = parseInt(obj2.data, 10)
    if (!val1) {
      return -1 * (order === 'asc' ? 1 : -1)
    }
    if (!val2) {
      return 1 * (order === 'asc' ? 1 : -1)
    }
    if (val1 === val2) {
      return compareStringsFunc(order, obj1.rowData[0], obj2.rowData[0])
    }
    return (val1 - val2) * (order === 'asc' ? 1 : -1)
  }
}

export const compareNumbersFunc = (order, property, obj1, obj2, secondary: string = 'wellId') => {
  const val1 = parseInt(obj1[property], 10)
  const val2 = parseInt(obj2[property], 10)
  if (!val1) {
    return -1 * (order === 'asc' ? 1 : -1)
  }
  if (!val2) {
    return 1 * (order === 'asc' ? 1 : -1)
  }
  if (val1 === val2) {
    return compareStrings(order, secondary, obj1, obj2)
  }
  return (val1 - val2) * (order === 'asc' ? 1 : -1)
}

/**
 * caseInsensitiveSort
 * Usual sort will put UPPER case string before lower case (Aaa, Bbb, aaa, bbb)
 * This is a case insensitive sort function (Aaa, aaa, Bbb, bbb)
 *
 * @param { string | number | boolean | null | undefined } a
 * @param { string | number | boolean | null | undefined } b
 *
 */
const caseInsensitiveSort = (a, b): number => {
  let valA = a
  let valB = b

  if (a === null || a === undefined) {
    valA = ''
  }

  if (b === null || b === undefined) {
    valB = ''
  }

  // coerce Number or Boolean to String and compare
  return `${valA}`.localeCompare(`${valB}`)
}

/**
 * getCustomSort
 *
 * Returns a customSort function that sorts fields correctly using case-insensitive algorithm
 * Sorts dates correctly too
 *
 * @param { string } fieldName
 * @param { string? } dateFormat optional
 *
 * @returns { function customSort (row1, row2) }
 */
export const getCustomSort =
  (fieldName: string, dateFormat = DATE_TIME_FORMAT) =>
  (row1, row2): number => {
    let value1 = row1[fieldName]
    let value2 = row2[fieldName]

    // NOTE: true = strict parsing - must match format exactly
    const isDate = dayjs(value1, dateFormat, true).isValid()

    if (isDate) {
      value1 = dayjs(value1, dateFormat).format()
      value2 = dayjs(value2, dateFormat).format()
    }

    if (typeof value1 === 'number' && typeof value2 === 'number') {
      return value1 - value2
    }

    return caseInsensitiveSort(value1, value2)
  }

/**
 * addCustomSort
 *
 * Adds customSort and defaultSort props to the respective columns
 *
 * @param { [{}] } columns
 * @param { string | string[] } sortBy  e.g. 'priority:desc'
 *
 */
export const addCustomSort = (columns, sortBy = '', isCustomSort = true) => {
  const [sortByField, sortOrder = 'desc'] = sortBy.split(':')

  return columns.map(col => {
    const column = isCustomSort ? { customSort: getCustomSort(col.field), ...col } : { ...col }

    // NOTE: The first column that has 'defaultSort' prop will be sorted on table load
    if (col.field === sortByField) {
      column.defaultSort = sortOrder
    }

    return column
  })
}

/**
 * onSortOrderChange
 *
 * Adds query string to the URL bar
 * Used in MaterialTable
 *
 * @param { [{}] } columns
 *
 */
export const onSortOrderChange = columns => (colIndex, sortOrder) =>
  Qs.set({ sortBy: `${columns[colIndex].field}:${sortOrder}` })

import { includes } from 'lodash'

import { formatDate, Qs } from '.'

/**
 * NOTE: currently not in use because all relevant dates (createdAt, updatedAt, expiry)
 * in the tables are already in DD/MM/YYY HH:mm:ss format
 * Dimitri 11/04/21
 * 
 * dateSearch - Custom date search function
 *
 * Date format in table data (e.g. 2020-07-16T13:59:23+10:00) is different
 * to what's displayed in the table (e.g. 16/07/2020 13:59:23)
 * This breaks the search, e.g. there is no match when users search for 16/07/2020
 *
 * To fix it, use this function as a *customFilterAndSearch* prop on any columns that have date/time
 * e.g.
 * const columns = [{
      title: 'Created',
      field: 'createdAt',
      render: (rowData): string => formatDate(rowData.createdAt),
 -->  customFilterAndSearch: dateSearch,
    },
  ]
 *
 * @param { string } term - search term (whatever users type in the search field)
 * @param { object } rowData
 * @param { object } column - column props, e.g. title, field, render...
 *
 */
export const dateSearch = (term: string, rowData: object, column: { field: string }): boolean => {
  const formattedDate = formatDate(rowData[column.field])

  return includes(formattedDate, term)
}

/**
 * onSearchChange
 *
 * Adds query string to the URL bar
 * Used in MaterialTable
 *
 * @param { string } searchTerm
 *
 */
export const onSearchChange = searchTerm => {
  Qs.set({ search: searchTerm })
}

import { OptionsObject } from 'notistack'

import { ENQUEUE_MESSAGE, REMOVE_MESSAGE, NotifierActionTypes } from '../../actions/notification'

export interface NotificationStateType {
  key: string
  message: string
  options: OptionsObject
}

const reducer = (
  state: Array<NotificationStateType> = [],
  action: NotifierActionTypes
): Array<NotificationStateType> => {
  switch (action.type) {
    case ENQUEUE_MESSAGE:
      return state.concat({ ...action.payload })
    case REMOVE_MESSAGE:
      return state.filter(notification => notification.key !== action.payload)
    default:
      return state
  }
}

export default reducer

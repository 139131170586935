import * as ActionTypes from '../../actions/actionTypes'
import { WatchListType } from '../../types'

export interface WatchListsState {
  items: WatchListType[]
  isWatchListsLookUpPending: boolean
}

const initialState: WatchListsState = {
  items: [],
  isWatchListsLookUpPending: false,
}

const watchListsReducer = (state: WatchListsState = initialState, action): WatchListsState => {
  switch (action.type) {
    case ActionTypes.GET_WATCH_LISTS: {
      return {
        ...state,
        isWatchListsLookUpPending: true,
      }
    }
    case ActionTypes.GET_WATCH_LISTS_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        isWatchListsLookUpPending: false,
      }
    }
    case ActionTypes.UPDATE_WATCH_LIST_SUCCESS: {
      const items = [...state.items]
      const updatedWatchLists = action.payload
      updatedWatchLists.forEach(({ data: { body } }) => {
        const wlIndex = items.findIndex(({ name }) => name === body.name)
        if (wlIndex >= 0) {
          items[wlIndex] = body
        }

        if (body.oldName) {
          const rIndex = items.findIndex(({ name }) => name === body.oldName)
          if (rIndex >= 0) {
            items[rIndex] = body
          }
        }
      })
      return {
        ...state,
        items,
      }
    }
    case ActionTypes.CREATE_WATCH_LIST_SUCCESS: {
      return {
        ...state,
        items: [...state.items, action.payload],
      }
    }
    case ActionTypes.DELETE_WATCH_LIST_SUCCESS: {
      return {
        ...state,
        items: state.items.filter(wl => wl.name !== action.payload),
      }
    }
    default:
      return state
  }
}

export default watchListsReducer

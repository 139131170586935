import { isEqual, mapValues, omit, includes } from 'lodash'
import rolesList from '../components/Pages/Well/rolesList'

export const getUsername = user => {
  const userPrefix = user.email.split('@')
  return `${userPrefix[0]}@origin.com.au`
}

// revise this
export const myTaskCheck = (task, roles, username, tabStatus) => {
  let isMyTask = false
  const foundByValue = rolesList.find(x => x.value === task?.role)?.value
  const foundByLabel = rolesList.find(x => x.label === task?.role)?.value

  if (tabStatus === 'Escalated' && task.taskType === 'Manual Task') {
    isMyTask = task.escalatedBy?.some(user => user === username)
  }

  if (task?.status === 'Escalated') {
    isMyTask = task.escalatedBy?.some(user => user === username)
  }

  if (roles.includes(foundByValue) || roles.includes(foundByLabel)) {
    isMyTask = true
  }

  return isMyTask
}

// Is this function still being used? Can't seem to find any references to it.
// Dimitri, 27/07/20
// eslint-disable-next-line
export const setRoles = (role): any => {
  if (includes(role, 'WPA_WELL_PERFORMANCE_TECHNICIAN')) return 'Well Performance Tech'
  if (includes(role, 'WPA_PANEL_OPERATOR')) return 'Panel Operator'
  if (includes(role, 'WPA_PRODUCTION_ENGINEER')) return 'Production Engineer'
  if (includes(role, 'WPA_FIELD_OPERATOR')) return 'Field Operator'
  if (includes(role, 'WPA_WELL_INTERVENTION_LEAD')) return 'Well Intervention Lead'
  if (includes(role, 'WPA_DATA_SCIENTIST')) return 'Data Scientist'
}

/**
 * escapeString
 * Sanitize user input by escaping special characters
 *
 * @param { string } str
 *
 */
export const escapeString = (str: string): string => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  }

  const keys = Object.keys(map).join('')
  const regex = new RegExp(`[${keys}]`, 'gi')

  return str.replace(regex, match => map[match])
}

/**
 * makeRegex
 * Creates a Regex that matches a value of a given keyName in the URL
 *
 * @param { string } keyName
 *
 * @example keyName = starttime
 * will return (?<=starttime=).*?(?=&|$)
 *
 * if url = ...starttime=10&endtime=20, this Regex will match 10
 *
 * (?<=starttime=) - positive look behind - will match if `starttime=` is preceeding the match
 * .*? - match any character repeated lazily (e.g. minimum times)
 * (?=&|$) - positive look ahead - & or lineend ($) must follow the match
 *
 */
export const makeRegex = (keyName: string): RegExp => new RegExp(`(?<=${keyName}=).*?(?=&|$)`)

/**
 * roleToLabel
 * Converts role to a label (WPA_XXX1_XXX2 -> XXX1 XXX2)
 * e.g. WPA_WELL_PERFORMANCE_TECHNICIAN -> WELL PERFORMANCE TECHNICIAN
 *
 * @param { string } value
 *
 */
export const roleToLabel = (value: string): string =>
  value?.slice(4).replace(/_/g, ' ').replace('USERS', 'USER') ?? ''

/**
 * labelToRole
 * Converts label to a role (XXX1 XXX2 -> WPA_XXX1_XXX2)
 * e.g. WELL PERFORMANCE TECHNICIAN -> WPA_WELL_PERFORMANCE_TECHNICIAN
 *
 * @param { string } value
 *
 */
export const labelToRole = (value: string): string => {
  if (value === 'TRIAL USER FIELD') {
    return 'WPA_TRIAL_USER_FIELD'
  }

  const prefix = value.startsWith('WPA_') ? '' : 'WPA_'

  return `${prefix}${value.replace(/\s/g, '_').replace('USER', 'USERS')}`
}

/**
 * trimValues
 * Trims all values of an object (values can be string | number)
 *
 * @param { object } data
 *
 */
export const trimValues = (obj: { [key: string]: string | number } = {}): {} =>
  mapValues(obj, value => (typeof value === 'string' ? value.trim() : value))

/**
 * formatNumber
 * Author: Mark Colquhoun
 *
 */
export const formatNumber = (
  value: number | string | null,
  options: { precision?: number; comma?: boolean; empty?: string } = {}
): string => {
  const { precision = 2, comma = true, empty = '' } = options

  if (value === null || value === undefined) {
    return empty
  }

  if (isNaN(Number(value))) {
    throw new Error('Value passed to format is not a number')
  }

  let newValue = (+value).toFixed(precision)

  if (comma) {
    const numberParts = newValue.toString().split('.')

    newValue = numberParts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    newValue = numberParts.length > 1 ? `${newValue}.${numberParts[1]}` : newValue
  }

  return newValue
}

/**
 * isChanged
 * Compares two objects (removes 'tableData' property)
 *
 * @param { object } value1
 * @param { object } value2
 *
 * @returns { boolean }
 *
 */
export const isChanged = (value1, value2) =>
  !isEqual(
    omit(value1, ['tableData', 'suspensionTable', 'worOverridesTable']),
    omit(value2, ['tableData', 'suspensionTable', 'worOverridesTable'])
  )

export const exists = value => {
  return value !== null && value !== undefined
}

export const isInt = value => {
  return !isNaN(value) && Number.isInteger(parseFloat(value))
}

export const getUserInitials = email => {
  let result = '?'
  const name = email?.match(/^(?<fn>.*)\.(?<ln>.*)@(.*)$/)

  if (name) {
    result = `${name.groups?.fn[0].toUpperCase()}${name.groups?.ln[0].toUpperCase()}`
  }
  return result
}

export const DHGValid = 'DHG data not valid as per PI AF tag Downhole Gauge Data Valid'

export const encodeBase64 = (data: any): string => {
  return btoa(JSON.stringify(data))
}

export const decodeBase64 = (encodedData: string): any => {
  return JSON.parse(atob(encodedData))
}

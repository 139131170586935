import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILED,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILED,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  SuspensionActionTypes,
} from '../../actions/suspensions'
import { SuspensionType } from '../../types'

export interface SuspensionStateType {
  items: SuspensionType[]
  isSuspensionsPending: boolean
}

const initialState: SuspensionStateType = {
  items: [],
  isSuspensionsPending: false,
}

const suspensionReducer = (
  state: SuspensionStateType = initialState,
  action: SuspensionActionTypes
): SuspensionStateType => {
  switch (action.type) {
    case CREATE_REQUEST:
    case UPDATE_REQUEST: {
      return {
        ...state,
        isSuspensionsPending: true,
      }
    }

    case FETCH_FAILED:
    case CREATE_FAILED:
    case UPDATE_FAILED: {
      return {
        ...state,
        isSuspensionsPending: false,
      }
    }

    case FETCH_REQUEST: {
      return {
        ...state,
        items: [],
        isSuspensionsPending: true,
      }
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        isSuspensionsPending: false,
      }
    }

    case CREATE_SUCCESS: {
      return {
        ...state,
        items: [...state.items, action.payload],
        isSuspensionsPending: false,
      }
    }

    case UPDATE_SUCCESS: {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.sId !== action.payload.sId) {
            return item
          }

          return {
            ...item,
            ...action.payload,
          }
        }),
        isSuspensionsPending: false,
      }
    }

    default:
      return state
  }
}

export default suspensionReducer

import axios, { AxiosResponse } from 'axios'
import { queryStringBuilder } from '.'
import {
  GetSuspensionsActionType,
  CreateSuspensionsActionType,
  UpdateSuspensionsActionType,
} from '../actions/suspensions'
import { SuspensionType } from '../types'

export const getSuspensions = (
  request: GetSuspensionsActionType['payload']
): Promise<AxiosResponse<Array<SuspensionType>>> => {
  const url = `/suspensions${queryStringBuilder.build(request)}`
  return axios.get(url)
}

export const createSuspension = (
  request: CreateSuspensionsActionType['payload']
): Promise<AxiosResponse<SuspensionType>> => {
  return axios({
    method: 'post',
    url: '/suspensions',
    data: request,
  })
}

export const updateSuspension = (
  request: UpdateSuspensionsActionType['payload']
): Promise<AxiosResponse<SuspensionType>> => {
  return axios({
    method: 'patch',
    url: '/suspensions',
    data: request,
  })
}

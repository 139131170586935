import { StorageKeys } from '.'

export const getStoredValue = (key: StorageKeys): object => {
  const value: string | null = localStorage.getItem(key)
  return value ? JSON.parse(value) : {}
}

export const storeValue = (key: StorageKeys, value: string): void => {
  return localStorage.setItem(key, JSON.stringify(value))
}

export const removeStoredValue = (key: StorageKeys): void => {
  return localStorage.removeItem(key)
}

export const clearAll = (): void => {
  return localStorage.clear()
}

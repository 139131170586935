import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILED,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILED,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILED,
} from '../../actions/taskTypes'
import { TaskTypesType } from '../../types'

export interface TaskTypesState {
  readonly items: TaskTypesType[]
  readonly isTaskTypesLookUpPending: boolean
}

const initialState: TaskTypesState = {
  items: [],
  isTaskTypesLookUpPending: false,
}

const taskTypesReducer = (state: TaskTypesState = initialState, action): TaskTypesState => {
  switch (action.type) {
    case CREATE_REQUEST:
    case DELETE_REQUEST:
    case UPDATE_REQUEST: {
      return {
        ...state,
        isTaskTypesLookUpPending: true,
      }
    }

    case FETCH_REQUEST: {
      return {
        ...state,
        items: [],
        isTaskTypesLookUpPending: true,
      }
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        isTaskTypesLookUpPending: false,
      }
    }

    case CREATE_SUCCESS: {
      return {
        ...state,
        items: [action.payload, ...state.items],
        isTaskTypesLookUpPending: false,
      }
    }

    case UPDATE_SUCCESS: {
      const updatedTaskType = action.payload

      const items = [...state.items]
      const itemIndex = items.findIndex(
        ({ taskTypeId }) => taskTypeId === updatedTaskType.taskTypeId
      )

      items[itemIndex] = updatedTaskType

      return {
        ...state,
        items,
        isTaskTypesLookUpPending: false,
      }
    }

    case DELETE_SUCCESS: {
      return {
        ...state,
        items: state.items.filter(item => item.taskTypeId !== action.payload),
        isTaskTypesLookUpPending: false,
      }
    }

    case FETCH_FAILED:
    case CREATE_FAILED:
    case DELETE_FAILED:
    case UPDATE_FAILED: {
      return {
        ...state,
        isTaskTypesLookUpPending: false,
      }
    }
    default:
      return state
  }
}

export default taskTypesReducer

import { flatMap, groupBy } from 'lodash'

import * as ActionTypes from '../../actions/actionTypes'
import { TasksState } from '../../actions/tasksTypes'
import { TaskType } from '../../types'
import { STATUS } from '../../constants'
import { getUsername } from '../../utils'

//TODO: define any
const initialState: any = {
  data: {},
  isPending: false,
}

const wellsByOpenTasksReducer = (state: any = initialState, action): TasksState => {
  switch (action.type) {
    case ActionTypes.GET_WELLS_BY_OPENTASKS: {
      return {
        ...state,
        isPending: true,
      }
    }

    case ActionTypes.GET_WELLS_BY_OPENTASKS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isPending: false,
      }
    }

    default:
      return state
  }
}

export default wellsByOpenTasksReducer

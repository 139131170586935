import { isURL, isLength, isFloat, isEmpty } from 'validator'
import isInt from 'validator/lib/isInt'
import isUUID from 'validator/lib/isUUID'

export const getInvalidLinkErrorMessage = (isInvalidLink: boolean): string | undefined => {
  if (isInvalidLink) {
    return 'Invalid link provided'
  }
  return undefined
}

export const isInvalidUrl = (value: string): boolean => {
  return value ? !isURL(value) : false
}

export const isEmptyValue = (value: string | number | boolean | undefined): boolean => {
  if (typeof value !== 'boolean' && !value) {
    return true
  }
  const mutatedValue = typeof value !== 'string' ? value.toString() : value
  return isEmpty(mutatedValue, { ignore_whitespace: true }) // eslint-disable-line @typescript-eslint/camelcase
}

export const isValidLengthString = (value: string, maxLength: number): string | undefined => {
  if (!isLength(value, { min: 1, max: maxLength })) {
    return `Maximum length allowed: ${maxLength}`
  }
  return undefined
}

export const isValidNumber = (value: number | string, maxValue: number): string | undefined => {
  if (
    !isFloat(value.toString(), { min: 0.0, max: maxValue }) ||
    !isInt(value.toString(), {
      min: 0,
      max: maxValue,
      // eslint-disable-next-line @typescript-eslint/camelcase
      allow_leading_zeroes: false,
    })
  ) {
    return `${value} should be less than ${maxValue}`
  }
  return undefined
}

export const isValidRedirectUrl = (url: string): boolean => {
  const trimmedUrl: string = url.trim()
  const urlParts: string[] = trimmedUrl.split('/')
  return (
    urlParts &&
    urlParts.length === 2 &&
    (urlParts[0] === 'approve' || urlParts[0] === 'workbook') &&
    isUUID(urlParts[1])
  )
}

/**
 * validateSchema
 * Validates either the whole Yup schema or separate fields
 *
 * @param schema // yup schema
 * @param { any } value
 * @param { string } fieldName
 * @param { object } options // configure yup validate options
 *
 * @returns boolean or object with an error message (ErrorType, see below)
 * e.g. { isValid: false, helperText: error.message }
 *
 */

interface ErrorType {
  isValid: boolean
  helperText?: string
}

const validateSchema = (
  schema,
  value,
  fieldName?: string,
  options?: object
): ErrorType | boolean => {
  const yupOptions = { strict: true, ...options }

  try {
    if (fieldName) {
      // NOTE: validates one field of the schema
      schema.validateSyncAt(fieldName, { [fieldName]: value })
      // if validation above fails, it throws an error and we never reach next line
      return true
    }
    // NOTE: isValidSync validates the whole schema, not just separate fields
    // returns Boolean (doesn't throw an error)
    return schema.isValidSync(value, yupOptions)
  } catch (error) {
    return { isValid: false, helperText: error.message }
  }
}

export const validateField =
  (fieldName, schema) =>
  (rowData): ErrorType | boolean =>
    validateSchema(schema, rowData[fieldName], fieldName)

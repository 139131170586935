import { call, put, takeEvery, takeLatest } from '@redux-saga/core/effects'

import {
  GET_WATCH_LISTS,
  GET_WATCH_LISTS_SUCCESS,
  UPDATE_WATCH_LIST,
  UPDATE_WATCH_LIST_SUCCESS,
  CREATE_WATCH_LIST,
  CREATE_WATCH_LIST_SUCCESS,
  DELETE_WATCH_LIST,
  DELETE_WATCH_LIST_SUCCESS,
} from '../../actions/actionTypes'
import { enqueueErrorMessage } from '../../actions/notification'
import {
  UpdateWatchListsAction,
  CreateWatchListsAction,
  DeleteWatchListsAction,
} from '../../actions/watchListsTypes'
import { apiUrl, patchWatchList, postWatchList, deleteWatchList } from '../../api/watchLists'
import { apiGetter } from '../../api/apiGetter'

function* getWatchListsSaga({ payload }: any) {
  try {
    const { applyGlobalFilters } = payload ?? { applyGlobalFilters: false }
    const response = yield call(apiGetter, apiUrl, { applyGlobalFilters })
    yield put({ type: GET_WATCH_LISTS_SUCCESS, payload: response })
  } catch (error) {
    yield put(enqueueErrorMessage({ message: 'Failed to load Watch lists', error }))
  }
}

function* updateWatchListSaga({ payload }: UpdateWatchListsAction) {
  try {
    const response = yield call(patchWatchList, payload)
    yield put({ type: UPDATE_WATCH_LIST_SUCCESS, payload: response })
  } catch (error) {
    yield put(enqueueErrorMessage({ message: 'Failed to update Watch list', error }))
  }
}

function* createWatchListSaga({ payload }: CreateWatchListsAction) {
  try {
    const response = yield call(postWatchList, payload)
    yield put({ type: CREATE_WATCH_LIST_SUCCESS, payload: response.data.body })
  } catch (error) {
    yield put(enqueueErrorMessage({ message: 'Failed to create a Watch list', error }))
  }
}

function* deleteWatchListSaga({ payload }: DeleteWatchListsAction) {
  try {
    yield call(deleteWatchList, payload)
    yield put({ type: DELETE_WATCH_LIST_SUCCESS, payload })
  } catch (error) {
    yield put(enqueueErrorMessage({ message: 'Failed to delete a Watch list', error }))
  }
}

function* watchSagas() {
  yield takeLatest(GET_WATCH_LISTS, getWatchListsSaga)
  yield takeLatest(UPDATE_WATCH_LIST, updateWatchListSaga)
  yield takeEvery(CREATE_WATCH_LIST, createWatchListSaga)
  yield takeLatest(DELETE_WATCH_LIST, deleteWatchListSaga)
}

export const watchListsSagas = [watchSagas]

import * as ActionTypes from '../../actions/actionTypes'
import { NotesForTaskState } from '../../actions/tasksTypes'

const initialState: NotesForTaskState = {
  byTasks: {},
  isNotesForTaskLookUpPending: false,
  isPostNotePending: false,
}

const notesForTaskReducer = (
  state: NotesForTaskState = initialState,
  action
): NotesForTaskState => {
  switch (action.type) {
    case ActionTypes.GET_NOTES_FOR_TASK: {
      return {
        ...state,
        isNotesForTaskLookUpPending: true,
      }
    }
    case ActionTypes.GET_NOTES_FOR_TASK_SUCCESS: {
      return {
        ...state,
        byTasks: { ...state.byTasks, [action.payload.taskId]: action.payload.notes },
        isNotesForTaskLookUpPending: false,
      }
    }
    case ActionTypes.POST_NOTES: {
      return {
        ...state,
        isPostNotePending: true,
      }
    }
    case ActionTypes.POST_NOTES_SUCCESS: {
      const { taskId, noteId, isUpdate } = action.payload

      let taskNotes = [...(state.byTasks[taskId] || [])]

      if (isUpdate) {
        const index = taskNotes.findIndex(note => note.noteId === noteId)
        taskNotes[index] = action.payload
      } else {
        taskNotes = [action.payload, ...taskNotes]
      }

      return {
        ...state,
        byTasks: { ...state.byTasks, [taskId]: taskNotes },
        isPostNotePending: false,
      }
    }
    default:
      return state
  }
}

export default notesForTaskReducer

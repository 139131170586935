import * as ActionTypes from '../../actions/actionTypes'
import { FiltersType } from '../../types'

export interface FiltersState {
  filters: Partial<FiltersType>[]
  locations: []
  isFiltersLookUpPending: boolean
  isPostFiltersPending: boolean
  isLocationsLookUpPending: boolean
  initial: boolean
}

export const initialState: FiltersState = {
  filters: [
    {
      assetAreas: [],
      operatingAreas: [],
      facilities: [],
      wellOperatorRuns: [],
      wellDevelopmentPackages: [],
      wells: [],
      designatedPEs: [],
      preset: [],
    },
  ],
  locations: [],
  isFiltersLookUpPending: false,
  isLocationsLookUpPending: false,
  isPostFiltersPending: false,
  initial: true,
}

const filtersReducer = (state: FiltersState = initialState, action): FiltersState => {
  switch (action.type) {
    case ActionTypes.GET_FILTERS: {
      return {
        ...state,
        isFiltersLookUpPending: true,
      }
    }
    case ActionTypes.GET_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: [{ ...state.filters[0], ...action?.payload[0] }],
        isFiltersLookUpPending: false,
        initial: false,
      }
    }
    case ActionTypes.GET_LOCATIONS: {
      return {
        ...state,
        isLocationsLookUpPending: true,
      }
    }
    case ActionTypes.GET_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: action.payload,
        isLocationsLookUpPending: false,
      }
    }
    case ActionTypes.POST_FILTERS: {
      return {
        ...state,
        isPostFiltersPending: true,
      }
    }
    case ActionTypes.POST_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: [action.payload],
        isPostFiltersPending: false,
      }
    }
    default:
      return state
  }
}

export default filtersReducer

import { sortBy } from 'lodash'
import {
  DELETE_WELL_TAB,
  DELETE_WELL_TAB_SUCCESS,
  GET_WELL_TABS,
  GET_WELL_TABS_SUCCESS,
  UPSERT_WELL_TAB,
  UPSERT_WELL_TAB_SUCCESS,
} from '../../actions/actionTypes'

import { WellTab } from '../../types'

export interface WellTabsState {
  items: WellTab[]
  loading: boolean
}

const initialState: WellTabsState = {
  items: [],
  loading: false,
}

const wellTabsReducer = (state: WellTabsState = initialState, action): WellTabsState => {
  switch (action.type) {
    case GET_WELL_TABS: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_WELL_TABS_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload, 'sortOrder'),
        loading: false,
      }
    }

    case UPSERT_WELL_TAB: {
      return {
        ...state,
        loading: true,
      }
    }

    case UPSERT_WELL_TAB_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload, 'sortOrder'),
        loading: false,
      }
    }

    case DELETE_WELL_TAB: {
      return {
        ...state,
        loading: true,
      }
    }

    case DELETE_WELL_TAB_SUCCESS: {
      return {
        ...state,
        items: sortBy(action.payload, 'sortOrder'),
        loading: false,
      }
    }

    default:
      return state
  }
}

export default wellTabsReducer

import { dayjs } from '.'

export class NumberFormatter {
  // @ts-ignore
  formatter

  constructor() {
    this.formatter = new Intl.NumberFormat()
  }

  format(number: number | null | undefined | string): string | null {
    if (number === null || typeof number === 'undefined') {
      return null
    }
    return this.formatter.format(number.toString())
  }
}

export const showFloat = (
  value: number | undefined | null | string,
  limitTo?: number
): string | number => {
  if (!value) {
    return 0.0
  }
  const fixedTo = !limitTo ? 1 : limitTo
  return typeof value === 'string' ? Number(value).toFixed(fixedTo) : value.toFixed(fixedTo)
}

export const showDate = (date: string | undefined, showTime: boolean = false): string => {
  if (!date || date.length === 0) {
    return '-'
  }
  return dayjs(date)
    .local()
    .format(`DD/MM/YYYY${showTime ? ' HH:mm' : ''}`)
}

export const getDate = (date: string | Date | undefined): string => {
  if (!date || (typeof date === 'string' && date.length === 0)) {
    return ''
  }
  return dayjs(date).utc().toISOString()
}

export const sanitiseUrl = (value: undefined | string, secure: boolean = true): string => {
  if (!value) {
    return ''
  }
  if (value && value.length > 0 && !value.startsWith('http://') && !value.startsWith('https://')) {
    return secure ? `https://${value}` : `http://${value}`
  }
  return value
}

export const removeLeadingZeroNumber = (
  value: string | number | undefined | null
): string | number => {
  if (!value) {
    return 0
  }
  if (value.toString().indexOf('.') === -1 && value.toString().startsWith('0')) {
    // @ts-ignore
    const potentialInt = parseInt(value, 10)
    if (!isNaN(potentialInt)) {
      return potentialInt.toString()
    }
  }
  return value
}

import { STATUS } from '../../../constants'

const { ESCALATED, CLOSED, TO_DO } = STATUS

export const getQueryByStatus = (status: string, currentUserEmail: string) => {
  if (status.toLowerCase() === ESCALATED.toLowerCase()) {
    return {
      bool: {
        should: [
          {
            match: {
              status: 'Escalated',
            },
          },
          {
            match: {
              updateAction: 'Escalated',
            },
          },
        ],
      },
    }
  }

  if (status.toLowerCase() === CLOSED.toLowerCase()) {
    return {
      bool: {
        minimum_should_match: 1,
        should: [
          {
            match: {
              status: 'Completed',
            },
          },
          {
            match: {
              status: 'Disregarded',
            },
          },
        ],
      },
    }
  }

  if (status.toLowerCase() === TO_DO.toLowerCase()) {
    return {
      bool: {
        must: [
          {
            match: {
              status,
            },
          },
        ],
        must_not: [
          {
            match: {
              role: 'WPA_STP',
            },
          },
        ],
      },
    }
  }

  return {
    match: {
      status,
    },
  }
}

// export const getQueryByStatus = (status: string, currentUserEmail: string)  => {

//   if(status.toLowerCase() === ESCALATED.toLowerCase()) {
//     return ({
//       bool:{
//         should: [
//           {
//             bool: {
//               must: [
//                 {
//                   match: {
//                     status: "Escalated"
//                   }
//                 },
//                 {
//                   match: {
//                     "escalatedBy": currentUserEmail
//                   }
//                 }
//               ]
//             }
//           },
//           {
//             bool: {
//               must: [
//                 {
//                   match: {
//                     taskType: "Manual Task"
//                   }
//                 },
//                 {
//                   match: {
//                     "escalatedBy": currentUserEmail
//                   }
//                 }
//               ]
//             }
//           }
//         ]
//       }
//     })
//   }

//   if(status.toLowerCase() === CLOSED.toLowerCase()) {
//     return ({
//       bool:{
//         minimum_should_match: 1,
//         should: [
//           {
//             match: {
//               status: "Completed"
//             }
//           },
//           {
//             match: {
//               status: "Disregarded"
//             }
//           }
//         ]
//       }
//     })
//   }

//   return ({
//     match: {
//       status
//     }
//   })
// }

import axios, { AxiosResponse } from 'axios'

import { CreateWatchListsPayload, UpdateWatchListsPayload } from '../actions/watchListsTypes'

export const apiUrl = '/watch-lists'

export const patchWatchList = (watchLists: UpdateWatchListsPayload[]): Promise<AxiosResponse[]> =>
  axios.all(watchLists.map(wl => axios.patch(apiUrl, wl)))

export const postWatchList = (data: CreateWatchListsPayload): Promise<AxiosResponse> =>
  axios.post(apiUrl, data)

export const deleteWatchList = (name: string): Promise<AxiosResponse> =>
  axios.delete(apiUrl, { data: { name } })

import { Logger } from '@aws-amplify/core'
import { all, call, spawn, delay } from '@redux-saga/core/effects'

import { authSagas } from './auth'
import { tasksSagas } from './tasks'
import { filtersSagas } from './filters'
import { watchListsSagas } from './watchLists'
import { wellListsSagas } from './wellLists'
import { rolesSagas } from './roles'
import { usersSagas } from './users'
import suspensionSagas from './suspensions'
import { taskTypesSagas } from './taskTypes'
import { worsSagas } from './wors'
import { oerSagas } from './oer'
import { submissionsSagas } from './submissions'
import { wellReviewSagas } from './wellReviews'
import { wellReviewClassificationSagas } from './wellReviewClassifications'
import { downholeDiagramsSagas } from './downholeDiagrams'
import { wellTabSagas } from './wellTabs'

const logger = new Logger('configureRootSaga')

export default function* configureRootSaga() {
  const sagas = [
    ...authSagas,
    ...filtersSagas,
    ...rolesSagas,
    ...usersSagas,
    ...suspensionSagas,
    ...tasksSagas,
    ...taskTypesSagas,
    ...watchListsSagas,
    ...wellListsSagas,
    ...worsSagas,
    ...oerSagas,
    ...submissionsSagas,
    ...wellReviewSagas,
    ...wellReviewClassificationSagas,
    ...downholeDiagramsSagas,
    ...wellTabSagas,
  ]
  yield all(
    sagas.map(saga =>
      // eslint-disable-next-line func-names
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            logger.error(e)
          }
          yield delay(1000)
        }
      })
    )
  )
}

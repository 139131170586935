import { all, call, put, takeLatest } from '@redux-saga/core/effects'

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILED,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILED,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILED,
  CreateTaskTypesAction,
  UpdateTaskTypesAction,
  DeleteTaskTypesAction,
} from '../../actions/taskTypes'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'
import { apiUrl, postTaskTypes, patchTaskTypes, deleteTaskTypes } from '../../api/taskTypes'

function* getTaskTypesSaga() {
  try {
    const response = yield call(apiGetter, apiUrl)
    yield put({ type: FETCH_SUCCESS, payload: response })
  } catch (error) {
    yield all([
      put({ type: FETCH_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Task Types', error })),
    ])
  }
}

function* createTaskTypesSaga({ payload }: CreateTaskTypesAction) {
  try {
    const response = yield call(postTaskTypes, payload)
    yield put({ type: CREATE_SUCCESS, payload: response.data.body })
  } catch (error) {
    yield all([
      put({ type: CREATE_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to create Task Types', error })),
    ])
  }
}

function* updateTaskTypesSaga({ payload }: UpdateTaskTypesAction) {
  try {
    const response = yield call(patchTaskTypes, payload)
    yield put({ type: UPDATE_SUCCESS, payload: response.data.body })
  } catch (error) {
    yield all([
      put({ type: UPDATE_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to update Task Types', error })),
    ])
  }
}

function* deleteTaskTypesSaga({ payload }: DeleteTaskTypesAction) {
  try {
    yield call(deleteTaskTypes, payload)
    yield put({ type: DELETE_SUCCESS, payload })
  } catch (error) {
    yield all([
      put({ type: DELETE_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to delete Task Types', error })),
    ])
  }
}

function* watchSagas() {
  yield takeLatest(FETCH_REQUEST, getTaskTypesSaga)
  yield takeLatest(CREATE_REQUEST, createTaskTypesSaga)
  yield takeLatest(UPDATE_REQUEST, updateTaskTypesSaga)
  yield takeLatest(DELETE_REQUEST, deleteTaskTypesSaga)
}

export const taskTypesSagas = [watchSagas]

import { all, call, put, takeLatest } from '@redux-saga/core/effects'

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILED,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILED,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  CreateWorsAction,
  UpdateWorsAction,
} from '../../actions/wors'
import { enqueueErrorMessage } from '../../actions/notification'
import { apiGetter } from '../../api/apiGetter'
import { apiUrl, patchWor, postWor } from '../../api/wors'

function* getWorsSaga() {
  try {
    const response = yield call(apiGetter, apiUrl, { current: true })
    yield put({
      type: FETCH_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield all([
      put({ type: FETCH_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Well Operator Run Config', error })),
    ])
  }
}

function* createWorsSaga({ payload }: CreateWorsAction) {
  try {
    const response = yield call(postWor, payload)
    yield put({ type: CREATE_SUCCESS, payload: response.data.body })
  } catch (error) {
    yield all([
      put({ type: CREATE_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to create Well Operator Run Config', error })),
    ])
  }
}

function* updateWorsSaga({ payload }: UpdateWorsAction) {
  try {
    // if payload.to exists, a new record will be returned from API
    const response = yield call(patchWor, payload)
    const { worId, to } = payload

    if (to) {
      yield put({ type: CREATE_SUCCESS, payload: response.data.body })
      yield put({ type: UPDATE_SUCCESS, payload: { worId, to } })
    } else {
      yield put({ type: UPDATE_SUCCESS, payload: response.data.body })
    }
  } catch (error) {
    yield all([
      put({ type: UPDATE_FAILED, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to update Well Operator Run Config', error })),
    ])
  }
}

function* watchSagas() {
  yield takeLatest(FETCH_REQUEST, getWorsSaga)
  yield takeLatest(CREATE_REQUEST, createWorsSaga)
  yield takeLatest(UPDATE_REQUEST, updateWorsSaga)
}

export const worsSagas = [watchSagas]

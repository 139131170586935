import { GET_WELLS, GET_WELLS_AGGREGATION } from './actionTypes'

// search-wells action types
export enum NumberOperator {
  GTE = 'gte',
  LTE = 'lte',
}

export interface NumberFilterType {
  range: Partial<Record<NumberOperator, number>>
}

export interface GetWellsPayload {
  from?: number
  size?: number
  filters?: Record<string, string | string[] | NumberFilterType>
  attributes?: string[]
  sort?: Record<string, 'asc' | 'desc'>
  search?: string
  reset?: boolean
}

export enum FetchWellsContext {
  None,
  WellsList,
  WellReviews,
  WellsDashboard,
  WellDetails,
}

export interface GetWellsAction {
  type: typeof GET_WELLS
  context: FetchWellsContext
  payload: GetWellsPayload
}

export const getWellsAction = (
  payload: GetWellsPayload,
  context: FetchWellsContext
): GetWellsAction => ({
  type: GET_WELLS,
  context,
  payload,
})

// search wells aggregator action types
export enum Aggregator {
  AVG = 'avg',
  MAX = 'max',
  MIN = 'min',
  SUM = 'sum',
  COUNT = 'count',
  DISTINCT = 'distinct',
}

export interface GetWellsAggregatorPayload {
  aggs: Record<string, Aggregator[]>
  filters?: Record<string, string | string[] | NumberFilterType>
}

export interface GetWellsAggregationAction {
  type: typeof GET_WELLS_AGGREGATION
  payload: GetWellsAggregatorPayload
}

export const getWellsAggregationAction = (payload: GetWellsAggregatorPayload) => ({
  type: GET_WELLS_AGGREGATION,
  payload,
})

/* eslint-disable no-await-in-loop */
import axios from 'axios'

/**
 * apiGetter
 * When partial data is returned by the server, it also returns nextId that can be
 * used as a query string to request the rest of the data
 *
 * This function makes API get requests in a loop until all data is received
 * You can use it in sagas like this:
 * yield call(apiGetter, '/escalated-tasks', { status: 'To Do'})
 *
 * @param { string } url
 * @param { object } parameters query string, e.g. { name: 'Great'} -> will become: ?name=Great
 * @returns { array } result
 *
 */
export const apiGetter = async (url: string, parameters: object = {}): Promise<unknown[]> => {
  let params = parameters
  let hasNextId = true
  const result = []

  do {
    const response = await axios.get(url, { params })
    const { data, nextId } = response.data.body

    result.push(...data)

    params = { ...params, nextId }
    hasNextId = !!nextId
  } while (hasNextId)

  return result
}

export const apiGet = async (url: string, parameters: object = {}): Promise<unknown> => {
  let params = parameters

  const response = await axios.get(url, { params })

  return response.data
}

import { FETCH_SUCCESS, FETCH_FAILED, SubmissionsActionTypes } from '../../actions/submissions'
import { SubmissionHealthType, SubmissionOutcomesType } from '../../types'

export interface SubmissionStateType {
  outcomes: SubmissionOutcomesType[]
  health: SubmissionHealthType[]
}

const initialState: SubmissionStateType = {
  outcomes: [],
  health: [],
}

const submissionsReducer = (
  state: SubmissionStateType = initialState,
  action: SubmissionsActionTypes
): SubmissionStateType => {
  switch (action.type) {
    case FETCH_SUCCESS: {
      return { ...state, ...action.payload }
    }

    case FETCH_FAILED: {
      return state
    }

    default:
      return state
  }
}

export default submissionsReducer
